import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getChat, setFormType } from "../redux/actions"

export const AreaChatHook = () => {
    const bodyChat = useRef<HTMLDivElement>(null)
    const endChat = useRef<HTMLDivElement>(null)
    const { messageId } = useParams()
    const { listMessageIdsByConversationId } = useSelector(({ chat }) => chat)
    const { data } = useSelector(({ sendMessage }) => sendMessage)
    const listMessage = listMessageIdsByConversationId.get(Number(messageId)) 
    const dispatch = useDispatch()

    const [btnScrollBottom, setBtnScrollBottom] = useState(false)

    const handleScroll = useCallback(() => {
        if(!bodyChat.current) return
        const { scrollTop, scrollHeight, clientHeight } = bodyChat.current
        setBtnScrollBottom(scrollTop + clientHeight < scrollHeight - 400 )
    }, [])

    const scrollToBottom = () => {
        if(!endChat.current) return
        endChat.current.scrollIntoView({behavior:"smooth", block: "end"})
    }

    useEffect(() => {
        if (!bodyChat.current) return;
        const currentScrollRef = bodyChat.current;
        currentScrollRef.addEventListener('scroll', handleScroll);
        currentScrollRef.addEventListener('touchmove', handleScroll);
        return () => {
            currentScrollRef.removeEventListener('scroll', handleScroll);
            currentScrollRef.removeEventListener('touchmove', handleScroll);
        } 
    }, [handleScroll])

    useEffect(() => {
        if(listMessage?.listIds.length > 0 && listMessage?.nextPage < 3) {
            if(!bodyChat.current) return
            bodyChat.current.scrollTop = bodyChat.current.scrollHeight
        }
    }, [listMessage?.listIds, listMessage?.nextPage])

    useEffect(() => {
        if(!endChat.current) return
        endChat.current.scrollIntoView({behavior:"smooth", block: "end"})
    }, [data])

    useEffect(() => {
        dispatch(setFormType("chat"))
    }, [dispatch, messageId])

    useEffect(() => {
        if(!listMessage) {
            dispatch(getChat(Number(messageId), { page: 1 }))
        }
    }, [dispatch, messageId, listMessage])

    return {
        bodyChat,
        endChat,
        btnScrollBottom,
        scrollToBottom
    }

}