import { combineReducers } from "redux";
import { AuthReducer } from "./auth.reducer";
import { ConversationReducer } from "./conversation.reducer";
import { FriendReducer } from "./friend.reducer";
import { FriendRequestReducer } from "./friendRequest.reducer";
import { SearchFriendReducer } from "./searchFriend.reducer";
import { ChatReducer } from "./chat.reducer";
import { SendMessageReducer } from "./sendMessage.reducer";
import { FileReducer } from "./file.reducer";
import { CallReducer } from "./call.reducer";

const rootReducer = combineReducers({
    auth: AuthReducer,
    conversation: ConversationReducer,
    friend: FriendReducer,
    friendRequest: FriendRequestReducer,
    searchFriend: SearchFriendReducer,
    chat: ChatReducer,
    sendMessage: SendMessageReducer,
    file: FileReducer,
    call: CallReducer
  })

export default rootReducer