'use client'

import { useEffect, useState, ElementType } from 'react';

export const LottieFile = ({
    src,
    width,
    height
}: {
    src: string,
    width: number
    height: number
}) => {
    const [Player, setPlayer] = useState<ElementType | null>(null);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const { Player } = require('@lottiefiles/react-lottie-player');
            setPlayer(() => Player);
          }
      }, []);
    return Player ?  <Player
        autoplay
        speed={1.5}
        loop
        src={src}
        style={{ height: height, width: width }}
      />
    : null
}