import { ChangeEvent, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getConversation } from "../redux/actions"

export const ListConversationHook = () => {
    const dispatch = useDispatch()
    const { nextPage, formType } = useSelector(({conversation}) => conversation)
    const [search, setSearch] = useState('')

    const HandleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
        dispatch(getConversation({page: 1, keyword: value, }))
    }

    const loadingInfinity = () => {
        dispatch(getConversation({ page: nextPage, keyword: search }))
    }

    const data = ['contact', 'friendRequest', 'setting', 'createGroup']

    return {
        data,
        formType,
        search,
        HandleSearch,
        loadingInfinity
    }
}