import { ClipboardEvent, FormEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { Emoji } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { InserTextOrEmoji, sendMessageAwait } from "../redux/actions";
import { useParams } from "react-router-dom";
import { FactoryRepository } from "../services";
import { v4 as uuidv4 } from 'uuid';

const repository = FactoryRepository.get("media")

export const MenuChatHook = () => {
    const [height, setHeight] = useState(37); // Giá trị ban đầu của chiều cao
    const contentEditableDivRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch()
    const { messageId } = useParams()
    const { data } = useSelector(({ conversation }) => conversation)
    const conversationId = data.get(Number(messageId))

    useEffect(() => {
        if(!contentEditableDivRef.current) return
        const currentContentEditableDivRef = contentEditableDivRef.current
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
              if (entry.target === currentContentEditableDivRef) {
                setHeight(entry.contentRect.height + 16)
              }
            }
          });
        resizeObserver.observe(currentContentEditableDivRef);
      
        return () => {
            if (currentContentEditableDivRef) {
              resizeObserver.unobserve(currentContentEditableDivRef);
            }
        };
    }, [])

    const onInput = (e: FormEvent<HTMLDivElement>) => {
        let htmlString = (e.target as HTMLDivElement).innerHTML;
        let parser = new DOMParser();
        let doc = parser.parseFromString(htmlString, 'text/html');
        let textWithEmojis = '';
        for (let node of doc.body.childNodes) {
            if (node.nodeName === 'IMG') {
                let imgNode = node as HTMLImageElement;
                textWithEmojis += imgNode.alt;
            } else {
                textWithEmojis += node.textContent;
            }
        }
        // lưu tin nhắn
        dispatch(InserTextOrEmoji(textWithEmojis, Number(messageId))) 
    }
    const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if(e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            // gửi tin nhắn
            dispatch(sendMessageAwait(uuidv4(), Number(messageId), {
                type: "TEXT",
                content: {
                    text: conversationId?.inputText
                }
            }))
            // xóa nội dung sau khi gửi
            dispatch(InserTextOrEmoji('', Number(messageId))); 
            (e.target as HTMLDivElement).innerText = ''
        }
    }

    const onPaste = async (e: ClipboardEvent<HTMLDivElement>) => {
        e.preventDefault();
        const originalText = e.clipboardData.getData('text');
        let text = originalText;
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            // Only delete the selected text
            selection.getRangeAt(0).deleteContents();
            // Replace all emojis in the text with img tags
            Emoji.forEach((value, key) => {
                const regex = new RegExp(key, 'g');
                text = text.replace(regex, `<img src="/emoji/${value}.webp" width="18" height="18" class="inline-block" alt="${key}">`);
            });
            // Insert the modified text
            const fragment = document.createRange().createContextualFragment(text);
            selection.getRangeAt(0).insertNode(fragment);
            if(contentEditableDivRef.current) {
                dispatch(InserTextOrEmoji(contentEditableDivRef.current.innerText, Number(messageId))); 
            }
        }
        if (e.clipboardData.items) {
            // Loop over all items in the clipboard
            for (let i = 0; i < e.clipboardData.items.length; i++) {
                let item = e.clipboardData.items[i];
                    if (item.type.indexOf("image") === 0) {
                    // If the item is an image, create a blob from it
                    let blob = item.getAsFile();
                    if (blob) {
                        const uuid = uuidv4()
                        const chunkSize = 2 * 1024 * 1024;

                        const chunks = [];
                        for (let i = 0; i < blob.size; i += chunkSize) {
                            chunks.push(blob.slice(i, i + chunkSize));
                        }

                        // Mảng để lưu trữ các Promise
                        const promises = [];

                        // Gửi từng phần đến máy chủ thông qua API
                        for (const [index, chunk] of chunks.entries()) {
                            const formData = new FormData();
                            formData.append('fileUuid', uuid);
                            formData.append('file', chunk);

                            // Tạo và lưu trữ Promise cho mỗi phần
                            const promise = new Promise(async resolve => {
                                let success = false;
                                while (!success) {
                                    try {
                                        await repository.chunk(formData);
                                        success = true;
                                        resolve(true);
                                    } catch (error) {
                                        // Xử lý lỗi và thử lại hoặc hiển thị thông báo lỗi
                                        console.log('lỗi hệ thống')
                                    }
                                }
                            })
                            promises.push(promise);
                        }
                        // Đợi cho đến khi tất cả các Promise đã được giải quyết
                        await Promise.all(promises);

                        // Gộp các phần lại với nhau trên máy chủ
                        const { data } = await repository.merge(uuid, `width=240height=240.png`, Number(messageId));
                        if(data) {
                            console.log(data)
                            dispatch(sendMessageAwait(uuidv4(), Number(messageId), {
                                type: "MEDIA",
                                content: {
                                    text: 'test'
                                },
                                medias: [
                                    {
                                        contentType: data.extension,
                                        text: null,
                                        mimeType: data.extension,
                                        filename: data.path.split("/").pop(),
                                        url: data.path,
                                        size: blob.size
                                    }
                                ]
                            }))
                        }
                    }
                }
            }
        }
    }

    const handleEmojiClick = (unicode: string) => {
        if (contentEditableDivRef.current) {
            contentEditableDivRef.current.focus()
            const selection = window.getSelection();
            if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const img = document.createElement('img');
                img.src = `/emoji/${Emoji.get(unicode)}.webp`
                img.width = 18
                img.height = 18
                img.className = 'inline-block' 
                img.alt =`${unicode}`
                range.insertNode(img);
                range.collapse(false);
                const newRange = document.createRange();
                newRange.setStartAfter(img);
                newRange.setEndAfter(img);
                selection.removeAllRanges();
                selection.addRange(newRange);
            }
            // Manually trigger the onInput event
            const event = new Event('input', { bubbles: true });
            contentEditableDivRef.current.dispatchEvent(event);
        }
    }
    
    return {
        handleEmojiClick,
        height,
        contentEditableDivRef,
        onInput,
        onKeyDown,
        onPaste
    }
}