export const START_RECORD_REQUEST = 'START_RECORD_REQUEST'
export const START_RECORD_SUCCESS = 'START_RECORD_SUCCESS'
export const START_RECORD_FAILED = 'START_RECORD_FAILED'

export const STOP_RECORD_REQUEST = 'STOP_RECORD_REQUEST'
export const STOP_RECORD_SUCCESS = 'STOP_RECORD_SUCCESS'
export const STOP_RECORD_FAILED = 'STOP_RECORD_FAILED'

export const INSERT_TEXT_EMOJI = 'INSERT_TEXT_EMOJI'
export const INSERT_FILE = 'INSERT_FILE'

export const UPDATE_AUDIO_LEVEL = 'UPDATE_AUDIO_LEVEL'

export const CHECK_TYPING = 'CHECK_TYPING'