import { Transition } from "@headlessui/react"
import { Fragment, ReactNode } from "react"

export const SlideTransition = ({ children, open }: { children: ReactNode, open: boolean }) => {
    return <Transition
        show={open}
        as={Fragment}
        enter="transition transform duration-300 "
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition transform duration-300"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
    >
        <div className="w-full h-full relative z-10">
            {children}
        </div>
    </Transition>
}