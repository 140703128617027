import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { EndCall, getConversation } from "../../../redux/actions";
import { ChatArea, LeftSidebarFactory } from "../../organisms";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import { DialogMolecules } from "../../molecules/dialog";
import { CallMolecules } from "../../molecules/call";
import Peer from "simple-peer";
import { useSocket } from "../../../socket/socket.provider";

export const Private = () => {
    const dispatch = useDispatch()
    const { messageId } = useParams()
    const { socketConversation } = useSocket()
    useEffect(() => {
        dispatch(getConversation({ page: 1 }))
    }, [dispatch])
    const { profile: { id } } = useSelector(({auth}) => auth)
    const { data } = useSelector(({conversation}) => conversation)
    const conversationId = data.get(Number(messageId))

    const { formType } = useSelector(({auth}) => auth)

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 926px)'
      })

    const { startCall, receiveCall } = useSelector(({call}) => call)

    const right = classNames(
        'transition-all w-full flex flex-col z-20 bg-[#7AA76D]',
        {
            'flex-1': isDesktopOrLaptop,
            'absolute top-0 left-0 h-full': !isDesktopOrLaptop && formType === 'chat',
            'absolute top-0 left-0 h-full translate-x-[420px]': !isDesktopOrLaptop && formType === 'conversation'
        }
    )

    const userVideoRef = useRef<HTMLVideoElement>(null);
    const [userVideo, setUserVideo] = useState<MediaStream>();
    const partnerVideoRef = useRef<HTMLVideoElement>(null);
    const [partnerVideo, setPartnerVideo] = useState<MediaStream>();

    const endCall = () => {
        dispatch(EndCall())
        if(userVideo) {
            userVideo.getTracks().forEach((track) => track.stop());
        }
        if(partnerVideo) {
            partnerVideo.getTracks().forEach((track) => track.stop());
        }
    }

    useEffect(() => {
        if(startCall !== null || receiveCall !== null) {
            // Yêu cầu quyền truy cập vào thiết bị máy ảnh và microphone
            navigator.mediaDevices
                .getUserMedia({ video: true, audio: true })
                .then((stream) => {
                    // Gán stream vào đối tượng video của người dùng
                    if(!userVideoRef.current) return;
                    userVideoRef.current.srcObject = stream;
                    // Lưu trữ đối tượng video vào biến trạng thái
                    setUserVideo(stream);

                    if(socketConversation?.socket) {
                        // Tạo một đối tượng simple-peer mới
                        const peer = new Peer({
                            initiator: true,
                            trickle: false,
                            stream: stream,
                        });

                        // // Khi peer tạo ra một tín hiệu, gửi nó cho đối tác thông qua socket
                        peer.on("signal", (data) => {
                            // Gửi tín hiệu qua người được gọi
                            if(startCall !== null) {
                                socketConversation?.socket.emit('call-user', {
                                    userId: startCall?.sendTo,
                                    conversationId: Number(messageId),
                                    signal: data
                                })
                            }
                            // Gửi tín hiệu lại cho người gọi
                            if(receiveCall !== null) {
                                socketConversation?.socket.emit('answer-call', {
                                    userWasCallId: receiveCall?.fromTo,
                                    conversationId: Number(messageId),
                                    signal: data
                                })
                            }
                        })

                        // peer.on("stream", (stream) => {
                        //     setPartnerVideo(stream);
                        //     if(!partnerVideoRef.current) return
                        //     partnerVideoRef.current.srcObject = stream;
                        // })
                        // Khi peer nhận được stream từ đối tác, gán nó vào đối tượng video của đối tác
                        if(receiveCall !== null) {
                            peer.signal(receiveCall.singalData)
                        }
                    }
                })
        }
    }, [startCall, receiveCall, messageId, id, conversationId?.conversationUsers])

    return (<div className="w-full h-full flex relative overflow-hidden">
            <LeftSidebarFactory type="conversation" />
            <div className={right}>
                {
                    messageId && <ChatArea />
                }
            </div>
            <DialogMolecules open={startCall !== null} closeModal={() => {}}>
                <CallMolecules 
                    endCall={endCall}
                    userVideo={userVideoRef}
                    partnerVideo={partnerVideoRef}
                />
            </DialogMolecules>
            <DialogMolecules open={receiveCall !== null} closeModal={() => {}}>
                <CallMolecules 
                    endCall={endCall}
                    userVideo={userVideoRef}
                    partnerVideo={partnerVideoRef}
                />
            </DialogMolecules>
        </div>
    )
}