import { BodyChatComponent, HeaderChat, InputChat } from "../../molecules"
import { AreaChatHook } from "../../../hooks"
import { Icon } from "../../../icon"
import { useSocket } from "../../../socket/socket.provider";

export const ChatArea = () => {
    const {bodyChat, endChat, btnScrollBottom, scrollToBottom} = AreaChatHook();
    return <>
        <HeaderChat />
        <div className="flex-1 relative z-10">
            <div className="top-0 left-0 w-full h-full flex justify-center absolute">
                <div className=" w-full flex flex-col items-center">
                    <div className="flex-1 relative w-full">
                        <div className="top-0 left-0 w-full h-full absolute flex">
                            <div className="flex-1 flex flex-col items-center">
                                <div ref={bodyChat} className="scrollbar-show-chat flex-1 py-2 flex flex-col items-center w-full overflow-auto">
                                    <BodyChatComponent />
                                    <div ref={endChat} />
                                </div>
                                <div className="max-w-[728px] w-full relative">
                                    {
                                        btnScrollBottom && <button style={{ boxShadow: '0 1px 8px 1px' }} onClick={scrollToBottom} className="w-[46px] h-[46px] bg-white rounded-full flex items-center justify-center right-4 bottom-4 absolute">
                                            <Icon type="arrowDown" />
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-[728px] w-full flex items-center px-4 pt-2">
                        <div className="pb-5 flex relative flex-1">
                            <div className="flex-1">
                                <InputChat scrollToBottom={scrollToBottom} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}