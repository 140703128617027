import { SagaIterator } from "redux-saga";
import { IQueryApi } from "../../../services/interfaces/query";
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from '../../types'
import { FactoryRepository } from "../../../services";

const repository = FactoryRepository.get('friendRequest')

const handle = async (query: IQueryApi) => {
    return await repository.search(query)
}

function* searchFriend(action: any): SagaIterator {
    const page = action.payload.page
    try {
        yield put({type: types.LOADING_SEARCH_FRIEND_ON, payload: { check: true, page }})
        const result = yield call(handle, action.payload)
        yield put({type: types.FRIEND_SEARCH_SUCCESS, payload: result})
        yield put({type: types.LOADING_SEARCH_FRIEND_OFF, payload: { check: false, page }})
    } catch (error) {
        yield put({type: types.FRIEND_SEARCH_FAILED, payload: null})
        yield put({type: types.LOADING_SEARCH_FRIEND_OFF, payload: { check: false, page }})
    } 
}

export function* watchSearchFriend() {
    yield takeLatest(types.FRIEND_SEARCH_REQUEST, searchFriend)
}
