import { IQueryApi } from "../../interfaces/query";
import { BaseRepository } from "../base";
import repository from "../repository";

export class FriendRequestRepository extends BaseRepository {
    resource = 'client/friend-requests';

    async search(query: IQueryApi) {
        return await (await repository.get(`${this.resource}/search-friends?page=${query.page || 1}&per_page=${20}&keyword=${query.keyword || ''}`)).data;
    }

    async allFriend(query: IQueryApi) {
        return await (await repository.get(`${this.resource}/all-friends?page=${query.page || 1}&per_page=${20}&keyword=${query.keyword || ''}`)).data
    }

    async unFriend(id: number) {
        return await repository.post(`${this.resource}/un-friends`, { friendId: id })
    }
}
