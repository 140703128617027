import classNames from "classnames"
import { ReactNode } from "react"
import { LottieFile } from "../lottieFile"

export const Button = ({
    children,
    variant = 'filled',
    fullWidth = false,
    type = 'button',
    loading = false,
    onClick,
    disable = false,
    className
}: {
    children: ReactNode,
    variant?: 'filled' | 'text',
    fullWidth?: boolean,
    type?: 'button' | 'submit',
    loading?: boolean,
    onClick?: () => void,
    disable?: boolean,
    className?: string
}) => {
    const classes = classNames(
        'h-[54px] transition-all rounded-[10px] flex gap-2 items-center justify-center',
        className,
        {
            'w-full': fullWidth,
            'bg-[#3390ec] hover:bg-dark-primary-color text-white disabled:bg-[#0000001f] disabled:text-[#00000042]': variant === 'filled',
            'text-[#3390ec] bg-transparent hover:bg-[#3390ec14]': variant === 'text'
        }
    )

    return <button disabled={disable} onClick={onClick} type={type} className={classes}>
        {children}
        {
            loading
            ? <LottieFile src='/lottie_file/loading.json' width={40} height={40} /> 
            : ''
        }
    </button>
}