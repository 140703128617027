import { SagaIterator } from "redux-saga";
import { FactoryRepository } from "../../../services";
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from '../../types'
import { uploadFile } from "../file";
import { v4 as uuidv4 } from 'uuid';

const repository = FactoryRepository.get('conversation')

const handle = async (data: any) => {
    return await repository.create(data)
}

function* createConversationGroup(action: any): SagaIterator {
    try {
        yield put({type: types.LOADING_CONVERSATION_ON_GROUP, payload: true})
        if(action.payload.thumbnail) {
            const links = yield call(uploadFile, action.payload.thumbnail, uuidv4())
            const result = yield call(handle, {
                ...action.payload,
                thumbnail: links.url
            })
            yield put({type: types.CREATE_CONVERSATION_SUCCESS_GROUP, payload: result})
        }
        if(!action.payload.thumbnail) {
            const result = yield call(handle, action.payload)
            yield put({type: types.CREATE_CONVERSATION_SUCCESS_GROUP, payload: result})
        }
        yield put({type: types.SET_FORM_TYPE, payload: 'chat'})
        yield put({type: types.SET_FORM_CONVERSATION_TYPE, payload: null})
        yield put({type: types.LOADING_CONVERSATION_OFF_GROUP, payload: false})
    } catch (error) {
        yield put({type: types.CREATE_CONVERSATION_FAILED_GROUP})
        yield put({type: types.LOADING_CONVERSATION_OFF_GROUP, payload: false})
    }  
}

export function* watchCreateConversationGroup() {
    yield takeLatest(types.CREATE_CONVERSATION_REQUEST_GROUP, createConversationGroup)
}

