import { Button, Input } from "../../../atoms"
import { LoginHook } from "../../../../hooks"
import { useSelector } from "react-redux"

export const Login = () => {
    const { onSubmit, onChange, email, password, switchRegister } = LoginHook()
    const { loadingButton } = useSelector(({auth}) => auth)

    return <form onSubmit={onSubmit} className="flex flex-col gap-4 items-center w-[360px]">
    <Input onChange={onChange} name="email" label="Nhập email" />
    <Input onChange={onChange} name="password" type="password" label="Nhập mật khẩu" />
    <Button disable={email === '' || password === ''} loading={loadingButton} type="submit" fullWidth>
        Đăng nhập
    </Button>
    <Button onClick={switchRegister} variant="text" fullWidth>
        Đăng ký
    </Button>
</form>
}