import { Emoji } from "../../constants";

export function replaceEmojiAndLinks(str: string) {
    let newStr = str;
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    let breakRegex = /\/n/g;

    // Replace URLs with <a> tags
    newStr = newStr.replace(urlRegex, function(url) {
        return `<a href='${url}' class='underline text-[#5ca853]' target='_blank'>${url}</a>`;
    });

    // Replace emojis with <img> tags
    for (let [emoji, fileName] of Emoji) {
        let emojiRegex = new RegExp(emoji, 'g');
        newStr = newStr.replace(emojiRegex, `<img src="/emoji/${fileName}.webp" width="18" height="18" class="inline-block" alt="${emoji}">`);
    }

    // Replace /n with <br> tags
    newStr = newStr.replace(breakRegex, '<br>');

    return newStr.trim();
}

export function replaceBreaks(str: string) {
    let newStr = str;
    let breakRegex = /<br\s*\/?>/g;

    // Replace <br> tags with /n
    newStr = newStr.replace(breakRegex, '/n');

    return newStr.trim();
}

export function convertToHHMM(isoTimeString: Date): string {
    const date = new Date(isoTimeString);
    // console.log(date)
    // let localTimeString = new Date( date.toLocaleString("vi-VN", {timeZone: "Asia/Ho_Chi_Minh"}) )
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Thêm số 0 phía trước nếu giờ hoặc phút chỉ có một chữ số
    var hoursStr = hours < 10 ? '0' + hours.toString() : hours.toString();
    var minutesStr = minutes < 10 ? '0' + minutes.toString() : minutes.toString();

    return hoursStr + ':' + minutesStr; 
}

export const groupMessagesByDate = (messages: any[]) => {
    let today = new Date();
    return messages?.reduce((grouped, message) => {
        let date = new Date( message?.createdAt );
        let key;
        if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
            key = 'Hôm nay';
        } else if (date.getFullYear() === today.getFullYear()) {
            key = `Ngày ${date.getDate()}, Tháng ${date.getMonth()+1}`;
        } else {
            key = `Ngày ${date.getDate()}, Tháng ${date.getMonth()+1}, Năm ${date.getFullYear()}`;
        }
        if (!grouped[key]) {
            grouped[key] = [];
        }
        grouped[key].push(message);
        return grouped;
    }, {});
}
