import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { useEffect } from 'react';
import { getProfile } from './redux/actions';
import { Private, Public } from './components/templates';
import { useNavigate } from 'react-router-dom';

function App() {
  const dispatch = useDispatch()
  const { loading, profile } = useSelector(({auth}) => auth)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getProfile())
  }, [dispatch])

  useEffect(() => {
    if(!profile && !loading) {
      navigate('/')
    }
  }, [profile, navigate, loading])

  if(!loading) {
    return <div className='h-full '>
      {
        profile
        ? <Private />
        : <Public />
      }
    </div>
  }
  return null
}

export default App;
