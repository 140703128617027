import { memo } from "react"
import { MessageStatus, MessageType } from "../../../services/interfaces"
import { ImageMessage } from "./image"
import { TextMessage } from "./text"
import { FileMessage } from "./file/file.message"

export const MessageFactory = memo(({
    type,
    createdAt,
    text,
    userId,
    medias = [],
    uuid,
    status
}: {
    type: MessageType,
    createdAt: Date,
    userId: number
    text?: string,
    medias?: any[],
    uuid?: string,
    status: MessageStatus
}) => {
    // console.log(media)
    switch(type) {
        case 'TEXT':
            return <TextMessage 
                userId={userId} 
                createdAt={createdAt} 
                text={text ?? ''} 
                status={status}
            />
        case 'FILE':
            return <FileMessage
                userId={userId}
                createdAt={createdAt}
                text={text ?? ''}
                status={status}
                medias={medias}
            />
        default:
            return null
    }
})