import { ChangeEvent, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { sendMessageRedux } from "../../../redux/actions"
import { useParams } from "react-router-dom"
import { v4 as uuidv4 } from 'uuid';
import { MenuMolecules } from "./menu"
import { Icon } from "../../../icon"
import { ButtonMenu } from "../../atoms"

export const MenuMedia = () => {
    const inputRefMedia = useRef<HTMLInputElement>(null)
    const inputRefFile = useRef<HTMLInputElement>(null)
    // const [media, setMedia] = useState<File[]>([])
    const dispatch = useDispatch()
    const { messageId } = useParams()
    const [open, setOpen] = useState(false)

    const listMenu = [
        {
            text: 'Ảnh hoặc video',
            icon: 'image',          
            action: () => inputRefMedia?.current?.click()
        },
        {
            text: 'Tệp',
            icon: 'file',          
            action: () => inputRefFile?.current?.click()
        },
    ]

    const sendMessage =(e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if(files) {
            dispatch(sendMessageRedux(Number(messageId), {
                type: 'MEDIA',
                content: { text: 'test' },
                medias: Array.from(files).map((element: File) => ({
                    file: element,
                    uuid: uuidv4()
                }))
            }))
            e.target.value = '';
        }
    }

    return <>
        <input ref={inputRefMedia} className="hidden" multiple type="file" accept="video/*,image/*" onChange={sendMessage} />
        <input ref={inputRefFile} className="hidden" type="file" multiple onChange={sendMessage} />
        <div className="relative h-[2.125rem] aspect-square flex items-center justify-center">
            <MenuMolecules
                className="!min-w-max"
                placement="top-right"
                onOutsideClick={() => setOpen(false)}
                open={open} 
                ButtonComponent={<button onClick={() => setOpen(!open)} className={`mb-2 flex items-center justify-center ${open ? 'text-[#3390ec]' : 'text-[#707579] hover:text-[#3390ec]'} transition-all`}>
                    <Icon type="media" />
                </button>}
                list={listMenu.map((element: any) => (
                    <ButtonMenu key={element.text} onClick={element.action}>
                        <Icon type={element.icon} />
                        {element.text}
                    </ButtonMenu>
                ))}
            /> 
        </div>
        
    </>
}