import * as types from '../types'

const initialState = {
    data: new Map(),
    listIds: [] as number[],
    next: true,
    loading: true,
    loadingMore: false,
    nextPage: 2,
    formType: null,
    setting: null
  };

export const ConversationReducer = (state = initialState, action: any) => {
    const { type, payload } = action
    let newData = new Map(state.data)
    let newListIds = [...state.listIds]
    let newNextPage = state.nextPage
    switch(type){
        case types.GET_CONVERSATION_SUCCESS:
            const { current, data, next } = payload
            data?.forEach((item:any) => {
                if(!newData.has(item.id)) {
                    newData.set(item.id, item)
                }
                return null
            });
            
            if(Number(current) === 1) {
                newListIds = data?.map(({id}: {id: number}) => id) || []
            }
            if(Number(current) > 1) {
                newListIds = [...newListIds, ...(data?.map(({id}: {id: number}) => id) || [])]
                newNextPage++
            }
            return {
                ...state,
                listIds: newListIds,
                data: newData,
                next: next,
                nextPage: newNextPage
            }
        case types.CREATE_CONVERSATION_SUCCESS_FRIEND:
        case types.CREATE_CONVERSATION_SUCCESS_GROUP:
            if(!newData.has(payload.id)) {
                newData.set(payload.id, payload)
                newListIds = [payload.id, ...newListIds]
            }
            return {
                ...state,
                data: newData,
                listIds: newListIds
            }
        case types.SEND_MESSAGE_SUCCESS:
        case types.RECEIVE_MESSAGE:
            let indexlistIds = newListIds.indexOf(payload.conversationId);
            if(newData.has(payload.conversationId)) {
                //cập nhật tin nhắn latestMessage
                newData.set(payload.conversationId, {
                    ...newData.get(payload.conversationId),
                    latestMessage: {
                        type: payload.type,
                        userId: payload.userId,
                        message: payload.messageContent.text
                    },
                    latestMessageType: payload.type,
                    latestMessageUserId: payload.userId
                })
                // chuyển conversationId lên đầu trong listIds
                if (indexlistIds > 0) {
                    newListIds.splice(newListIds.indexOf(payload.conversationId), 1);
                    newListIds.unshift(payload.conversationId);
                }
            }
            if(!newData.has(payload.conversationId)) {
                // chưa có info của conversation
            }
            return {
                ...state,
                listIds: newListIds,
                data: newData
            }
        case types.SET_FORM_CONVERSATION_TYPE:
            return {
                ...state,
                formType: payload
            }
        case types.SET_FORM_SETTING_TYPE:
            return {
                ...state,
                setting: payload
            }
        case types.GET_CONVERSATION_LOADING_ON:
        case types.GET_CONVERSATION_LOADING_OFF:
            if(payload.page === 1) {
                return {
                    ...state,
                    loading: payload.check
                }
            }
            return {
                ...state,
                loadingMore: payload.check
            }
        case types.LOGOUT_SUCCESS:
            return initialState
        default:
            return state
    }
}