import { LeftSideBarType } from "../../../services/interfaces"
import { Contact } from "./contact"
import { CreateGroup } from "./createGroup"
import { FriendRequest } from "./friendRequest"
import ListConversation from "./listConversation"
import { SearchFriend } from "./searchFriend"
import { Setting } from "./setting"

export const LeftSidebarFactory = ({
    type
}: {
    type: LeftSideBarType,
}) => {
    switch(type) {
        case 'conversation': 
            return <ListConversation />
        case 'setting':
            return <Setting />
        case 'contact':
            return <Contact />
        case 'friendRequest':
            return <FriendRequest />
        case 'searchFriend':
            return <SearchFriend />
        case 'createGroup':
            return <CreateGroup />
        default:
            return null        
    }
}