import * as types from '../types'

const initialState = {
    data: new Map(),
};

export const FileReducer = (state = initialState, action:any) => {
    const { type, payload } = action
    let newData = new Map(state.data)
    switch(type){
        case types.SEND_MESSAGE_AWAIT:
            const { data } = payload
            data.medias?.forEach((item:any) => {
                if(!newData.has(item.uuid)) {
                    newData.set(item.uuid, {
                        ...item,
                        uploadSize: 0,
                        link: null
                    })
                }
                return null
            });
            return {
                data: newData
            }
        case types.FILE_UPLOAD_CHUNK:
            if(newData.has(payload.uuid)) {
                let fileData = {...newData.get(payload.uuid)}
                fileData.uploadSize = payload.uploadedSize
                newData.set(payload.uuid, fileData)
            }
            return {
                data: newData
            }
        default:
            return state
    }
}