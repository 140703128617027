export const GET_FRIEND_REQUEST = 'GET_FRIEND_REQUEST'
export const GET_FRIEND_SUCCESS = 'GET_FRIEND_SUCCESS'
export const GET_FRIEND_FAILED = 'GET_FRIEND_FAILED'

export const UN_FRIEND_REQUEST = 'UN_FRIEND_REQUEST'
export const UN_FRIEND_SUCCESS = 'UN_FRIEND_SUCCESS'
export const UN_FRIEND_FAILED = 'UN_FRIEND_FAILED'

export const LOADING_FRIEND_ON = 'LOADING_FRIEND_ON'
export const LOADING_FRIEND_OFF = 'LOADING_FRIEND_OFF'

export const LOADING_FRIEND_FIRST = 'LOADING_FRIEND_FIRST'