import * as types from '../types'

export const startRecord = () => ({
    type: types.START_RECORD_REQUEST
})

export const stopRecord = () => ({
    type: types.STOP_RECORD_REQUEST
})

export const InserTextOrEmoji = (text: string, conversationId: number) => ({
    type: types.INSERT_TEXT_EMOJI,
    payload: { text, conversationId }
})

export const checkTyping = (onProcess: boolean, conversationId: number) => ({
    type: types.CHECK_TYPING,
    payload: { onProcess, conversationId}
})