import { AuthRepository } from "./repositories/auth/auth.repository"
import { ChatRepository } from "./repositories/chat"
import { ConversationRepository } from "./repositories/conversation"
import { FriendRequestRepository } from "./repositories/friend"
import { MediaRepository } from "./repositories/media"

interface Irepository {
    authentication: AuthRepository,
    conversation: ConversationRepository,
    friendRequest: FriendRequestRepository,
    chats: ChatRepository,
    media: MediaRepository
}

const repository: Irepository = {
    authentication: new AuthRepository(),
    conversation: new ConversationRepository('conversations'),
    friendRequest: new FriendRequestRepository('client/friend-requests'),
    chats: new ChatRepository(),
    media: new MediaRepository(),
}

export const FactoryRepository = {
    get: <K extends keyof Irepository>(name: K) => repository[name]
}
