import * as types from '../types'

const initialState = {
    data: new Map(),
    listIds: [] as number[],
    next: true,
    loading: true,
    loadingMore: false,
    nextPage: 2
  };

export const FriendReducer = (state = initialState, action:any) => {
    const { type, payload } = action
    let newData = new Map(state.data)
    let newListIds = [...state.listIds]
    let newNextPage = state.nextPage
    switch(type){
        case types.GET_FRIEND_SUCCESS:
            const { current, data, next } = payload
            data?.forEach((item:any) => {
                if(!newData.has(item.id)) {
                    newData.set(item.id, item)
                }
                return null
            });
            
            if(Number(current) === 1) {
                newListIds = data?.map(({id}: {id: number}) => id) || []
            }
            if(Number(current) > 1) {
                newListIds = [...newListIds, ...(data?.map(({id}: {id: number}) => id) || [])]
                newNextPage++
            }
            return {
                ...state,
                listIds: newListIds,
                data: newData,
                next: next,
                nextPage: newNextPage
            }
        // case types.ACCESS_FRIEND_REQUEST_SUCCESS:
        //     newData.set(payload.data.friendId, payload.data)
        //     newListIds = [payload.data.friendId, ...newListIds]
        //     return {
        //         ...state,
        //         data: newData,
        //         listIds: newListIds
        //     }
        // case types.UN_FRIEND_SUCCESS:
        //     let indexlistIds = newListIds.indexOf(payload);
        //     if(newData.has(payload)) {
        //         if (indexlistIds > -1) {
        //             newListIds.splice(newListIds.indexOf(payload), 1);
        //             newData.delete(payload)
        //         }
        //     }
        //     return {
        //         ...state,
        //         data: newData,
        //         listIds: newListIds
        //     }
        case types.LOADING_FRIEND_ON:
        case types.LOADING_FRIEND_OFF:
            if(payload.page === 1) {
                return {
                    ...state,
                    loading: payload.check
                }
            }
            return {
                ...state,
                loadingMore: payload.check
            }
        case types.LOGOUT_SUCCESS:
            return initialState
        default:
            return state
    }
}
