import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { FC, ReactNode, useState, PropsWithChildren, Fragment, FocusEvent } from "react";

type ButtonComponentProps = PropsWithChildren<any>;

type ButtonComponentType = FC<ButtonComponentProps> | React.ReactElement;

type MenuMoleculesProps = {
    ButtonComponent: ButtonComponentType; 
    placement?: "bottom-start" | "bottom-end" | "bottom" | "right" | "right-start" | "right-end" | "top-right" | "top-left"
    list?: ReactNode[]
    open?: boolean,
    className?: string
    onOutsideClick?: () => void
    children?: ReactNode
}

export const MenuMolecules: FC<MenuMoleculesProps> = ({ children, ButtonComponent, placement = 'bottom-start', list = [], open, className = '', onOutsideClick  }) => {
    const [isOpen, setIsOpen] = useState(open);

    const classes = classNames(
        ' absolute min-w-[260px] w-max outline-none z-50 px-1.5',
        {
            'right-0 top-full pt-1': placement === 'bottom-end',
            'left-0 top-full pt-1': placement === 'bottom-start',
            'left-full top-0 pl-1': placement === 'right-start',
            'left-full bottom-0 pl-1': placement === 'right-end',
            'bottom-full right-0 pb-1': placement === 'top-right',
            'bottom-full left-0 pb-1': placement === 'top-left'
        },
        className
    )

    const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
        if (!event.currentTarget.contains(event.relatedTarget as Node)) {
            // Outside click 
            onOutsideClick && onOutsideClick();
        }
    }

    return <Menu as='div' className='relative w-full h-full flex items-center justify-center' onBlur={handleBlur}>
        <Menu.Button as="div" onClick={() => setIsOpen(!isOpen)} className='flex items-center'>
            {typeof ButtonComponent === 'function' ? <ButtonComponent /> : ButtonComponent}
        </Menu.Button>
        <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-200"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className={classes}>
                <div className="bg-white/90 backdrop-blur flex flex-col rounded-lg py-2" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, .15)' }}>
                    {
                        children 
                        ? children
                        : list.map((element, index) => (
                            <Menu.Item
                                as="div"
                                key={index}
                                className='flex-1 flex'
                            >
                                {element}
                            </Menu.Item>
                        ))
                    }
                </div>
            </Menu.Items>
        </Transition>
    </Menu>
}