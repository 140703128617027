import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import rootSagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({ 
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
})
sagaMiddleware.run(rootSagas);
