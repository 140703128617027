import { useDispatch, useSelector } from "react-redux"
import { InfinityScroll, SlideTransition } from "../../molecules";
import { Search } from "../../atoms";
import { ListConversationHook } from "../../../hooks";
import { animateScroll as scroll } from 'react-scroll';
import { LeftSidebarFactory } from ".";
import { MenuLeft, MenuPlus } from "../../molecules/menu2";
import { Link, useParams } from "react-router-dom";
import { ItemList } from "../../molecules/ItemList";
import { Icon } from "../../../icon";
import { MessageType } from "../../../services/interfaces";
import { setFormType } from "../../../redux/actions";

function IconLatestMessage({type}: {type: MessageType}) {
    switch(type) {
        case 'TEXT':
            return null
        case 'FILE':
            return <Icon type="media" className="!w-4 !h-4" />
        case 'MEDIA':
            return <Icon type="image" className="!w-4 !h-4" />
        case 'AUDIO':
            return <Icon type="micro" className="!w-4 !h-4" />
        default:
            return null
    }
}

export default function ListConversation() {
    const { formType, search, HandleSearch, loadingInfinity } = ListConversationHook()
    const { profile } = useSelector(({auth}) => auth)
    const { data: dataConversation } = useSelector(({conversation}) => conversation) 
    const data = ['contact', 'friendRequest', 'setting', 'createGroup', 'searchFriend']
    const { messageId } = useParams()
    const dispatch = useDispatch()

    return <div className="w-full h-full max-w-[420px] border-r border-[#dfe1e5] relative z-10 overflow-hidden">
        <div className="top-0 left-0 w-full h-full z-[1] flex flex-col bg-white absolute">
            <div className="h-[56px] px-4">
                <div className="flex h-full items-center">
                    <div className="h-10 w-10">
                        <MenuLeft />
                    </div>
                    <Search value={search} onChange={(e) => {
                        scroll.scrollToTop({ containerId: "left", duration: 0 });
                        HandleSearch(e)
                    } } />
                </div>
            </div>
            <div className="flex-1 relative">
                <div id='left' className="top-0 left-0 w-full h-full absolute overflow-auto scrollbar-show">
                    <InfinityScroll 
                        keyRedux="conversation" 
                        handle={loadingInfinity} 
                        text='Bạn chưa có cuộc hội thoại nào'
                        item={
                            value => {
                                const { conversationUsers, name, thumbnail, id, latestMessageType, latestMessage, latestMessageUserId  } = dataConversation.get(value)
                                const { fullName, lastAvatar } = conversationUsers.find((e: any) => e.userId !== profile.id)?.user
                                const nameConversation = name || ( conversationUsers.length === 2 ? fullName  : '')
                                const image = thumbnail || (conversationUsers.length === 2 ? lastAvatar  : '')
                                return <Link to={`/messages/${id}`} onClick={() => dispatch(setFormType("chat"))}>
                                    <ItemList 
                                        name={nameConversation}
                                        image={image}
                                        className={`${Number(messageId) === id ? '!bg-[#3390ec] !hover:bg-[#3390ec] !text-white' : ''} rounded-[10px]`}
                                        message={<span className={`${Number(messageId) === id ? ' !text-white' : ''} top-0 left-0 absolute w-full truncate`}>
                                            {
                                                latestMessageUserId === profile.id
                                                ? 'Bạn: '
                                                : conversationUsers.length > 2 && conversationUsers.find((e: any) => e.userId === latestMessageUserId)?.user?.fullName + ': '
                                            }
                                            <IconLatestMessage type={latestMessageType} />
                                            {latestMessage?.message}
                                        </span>}
                                    />
                                </Link>
                            } 
                        }
                    />
                </div>
                <div className="z-20 right-5 bottom-5 absolute bg-[#3390ec] hover:bg-dark-primary-color transition-all cursor-pointer rounded-full text-black flex items-center justify-center">
                    <MenuPlus />
                </div>
            </div>
        </div>
        {
            data.map((element: any) => (
                <SlideTransition key={element} open={formType === element} >
                    <LeftSidebarFactory type={element} />
                </SlideTransition>
            ))
        }
    </div>
}