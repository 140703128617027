export const GET_CHAT_REQUEST = 'GET_CHAT_REQUEST'
export const GET_CHAT_SUCCESS = 'GET_CHAT_SUCCESS'
export const GET_CHAT_FAILED = 'GET_CHAT_FAILED'

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST'
export const SEND_MESSAGE_AWAIT = 'SEND_MESSAGE_AWAIT'
export const DELETE_MESSAGE_AWAIT = 'DELETE_MESSAGE_AWAIT'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED'

export const RECEIVE_MESSAGE= 'RECEIVE_MESSAGE'

export const GET_CHAT_LOADING_ON = 'GET_CHAT_LOADING_ON'
export const GET_CHAT_LOADING_OFF = 'GET_CHAT_LOADING_OFF'