import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { InView } from 'react-intersection-observer';

export const InfinityScroll = ({
    keyRedux,
    handle,
    item: Item,
    text
}: {
    keyRedux: string,
    handle: () => void,
    item: (value: any) => ReactNode,
    text: string
}) => {
    const { listIds, loading, loadingMore, next } = useSelector((state: any) => state[keyRedux])

    return <div className="flex-1 px-2 flex flex-col relative">
        {
            loading 
            ? Array.from(Array(10).keys()).map(element => (
                <div key={element} className="p-4 max-w-sm w-full mx-auto">
                    <div className="animate-pulse flex space-x-4">
                        <div className="rounded-full bg-light-secondary-text-color h-10 w-10" />
                        <div className="flex-1 space-y-4 py-1">
                            <div className="flex justify-between">
                                <div className="h-2 bg-light-secondary-text-color w-12 rounded" />
                                <div className="h-2 bg-light-secondary-text-color w-8 rounded" />
                            </div>
                            <div className="h-2 bg-light-secondary-text-color rounded" />
                        </div>
                    </div>
                </div>
            ))
            : listIds.length === 0
            ? <div className="flex items-center justify-center w-full h-full">
                <span className="font-medium text-lg text-center">{text}</span>
            </div>
            : listIds?.map((element: any, index: number, array: any[]) => {
                if(array.length === index + 1) {
                    return <InView as="div" key={index} onChange={(inView) => {
                        if(inView && next && !loadingMore) { 
                            handle(); 
                        }
                    }}>
                        {Item(element)}
                    </InView>
                }
                return <div key={index}>{Item(element)}</div>
            })
        }
    </div>
}
