import { useSelector } from "react-redux"
import { MeMessage } from "../me"
import { YouMessage } from "../you"
import { MessageStatus } from "../../../../services/interfaces"
import { FileColor, ImageMimeType } from "../../../../constants"
import ReactHtmlParser from 'react-html-parser';
import { convertToHHMM, replaceEmojiAndLinks } from "../../../../services/editMessage"
import { StatusMessage } from "../../../atoms"
import { formatBytes } from "../../../../services/file"

export const FileMessage = ({
    userId,
    createdAt,
    text = '',
    medias = [],
    status
}: {
    userId: number,
    createdAt: Date,
    text: string,
    medias: any[],
    status: MessageStatus
}) => {
    const { profile } = useSelector(({auth}) => auth)
    const { data } = useSelector(({file}) => file)
    const IsMe = profile.id === userId

    const file = <div className={`flex flex-col gap-1 max-w-[480px] p-2 rounded-[15px] ${IsMe ? 'rounded-br-none bg-[#e3fee0]' : 'rounded-bl-none bg-white'} relative`}>
        {
            medias.map((element: any, index: number, array: any[]) => {
                let parts;
                let extension;
                let name;
                if(status === 'SENDING') {
                    const { file } = data.get(element)
                    parts = file.name.split('.');
                    name = file.name

                }
                if(status !== 'SENDING') {
                    parts = element.split('.');
                    // name = element.split("/").pop()
                }
                extension = parts.pop() ?? '';
                if(ImageMimeType.includes(extension)) {
                    return <div key={index}></div>
                }
                return <div key={index} className="pl-[3.5rem] flex flex-col relative">
                    <div className="top-0 left-0 absolute h-full aspect-square flex items-center justify-center text-white rounded-[.375rem]" style={{ backgroundColor: FileColor.get(extension) }}>
                        {extension}
                    </div>
                    {/* <span className="text-black font-bold truncate">{name}</span> */}
                    <span className="text-[#5ca853] text-[14px]">
                        {/* {
                            status === 'SENDING' 
                            ? data.get(element)?.link && formatBytes(data.get(element)?.file.size)
                            : !data.get(element)?.link && <>{formatBytes(data.get(element)?.uploadSize)} / {formatBytes(data.get(element).file.size)}</> 
                        } */}
                        {
                            (index + 1 === array.length && text === '') && <span className="ml-1 translate-y-1.5 text-[12px] float-right text-[#707579] flex items-center gap-0.5">
                                {convertToHHMM(createdAt)}
                                <StatusMessage status={status} />
                            </span>
                        }
                    </span>
                </div>
            })
        }
        <div style={{ wordBreak: "break-word" }} className="whitespace-normal max-w-[480px] relative">
            {ReactHtmlParser(replaceEmojiAndLinks(text))}
            {
                text !== '' && <span className="ml-1 translate-y-1.5 text-[12px] float-right text-[#707579] flex items-center gap-0.5">
                    {convertToHHMM(createdAt)}
                    <StatusMessage status={status} />
                </span>
            }
        </div>
    </div>
    if(IsMe) {
        return <MeMessage>
            {file}
        </MeMessage>
    }
    return <YouMessage
        userId={userId}
    >
        {file}
    </YouMessage>
}