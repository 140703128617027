import classNames from 'classnames';
import { ChangeEvent, useState } from 'react';

export const Input = ({
  label,
  name,
  disabled = false,
  className,
  value,
  onChange,
  type = 'text',
  error
}: {
  label: string,
  name?: string,
  disabled?: boolean,
  className?: string,
  value?: string,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  type?: 'text' | 'password',
  error?: string
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(value || '');

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (inputValue === '') {
      setIsFocused(false);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInputValue(value);
    if(onChange) {
      onChange(event)
    }
  };

  const clases = classNames(
    'h-full p-4 outline-none w-full relative placeholder:text-transparent rounded-[10px] border',
    className,
    {
      'border-[#3390ec]' : isFocused && !error,
      'border-[#dfe1e5] hover:border-[#3390ec]' : !isFocused && !error,
      'border-[#d32f2f]': error
    }
  )

  return <div className='flex flex-col gap-2 w-full relative'>
    <label onFocus={handleFocus} onBlur={handleBlur} className={`absolute left-0 -translate-y-1/2 ${isFocused ? 'bg-transparent top-0 text-[#3390ec] text-xs mx-1.5' : 'bg-transparent top-1/2 text-[#9e9e9e] bg-clip-text'} z-10 bg-white mx-4 transition-all pointer-events-none`}>{label}</label>
    <div className="relative min-w-full">
      <input 
        value={value}
        className={clases}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        name={name}
        disabled={disabled}
        type={type}
      />
    </div>
    {
      error && <div className='py-2.5 px-4 bg-[#fdeded] w-full'>
        {error}
      </div>
    }
  </div> 
}