import * as types from '../types'

const initialState = {
    data: new Map(),
    listIds: [] as number[],
    next: true,
    loading: true,
    loadingMore: false,
    nextPage: 2
  };

export const SearchFriendReducer = (state = initialState, action:any) => {
    const { type, payload } = action
    let newData = new Map(state.data)
    let newListIds = [...state.listIds]
    let newNextPage = state.nextPage
    switch(type){
        case types.FRIEND_SEARCH_SUCCESS:
            const { current, data, next } = payload
            data?.forEach((item:any) => {
                if(!newData.has(item.id)) {
                    newData.set(item.id, item)
                }
                return null
            });
            
            if(Number(current) === 1) {
                newListIds = data?.map(({id}: {id: number}) => id) || []
            }
            if(Number(current) > 1) {
                newListIds = [...newListIds, ...(data?.map(({id}: {id: number}) => id) || [])]
                newNextPage++
            }
            return {
                ...state,
                listIds: newListIds,
                data: newData,
                next: next,
                nextPage: newNextPage
            }
        case types.LOADING_SEARCH_FRIEND_ON:
        case types.LOADING_SEARCH_FRIEND_OFF:
            if(payload.page === 1) {
                return {
                    ...state,
                    loading: payload.check
                }
            }
            return {
                ...state,
                loadingMore: payload.check
            }
        case types.LOGOUT_SUCCESS:
            return initialState
        default:
            return state
    }
}
