import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSocket } from "../../../socket/socket.provider";
import { useNavigate } from 'react-router-dom';
import Peer from 'simple-peer'
// import { Peer } from "../../../WebRTC";


export const VideoCall = () => {
    const navigate = useNavigate()
    const { userCallId, type, conversationId } = useParams();
    // For testing
    const [callAccepted, setCallAccepted] = useState(false);
    const [callEnded, setCallEnded] = useState(false);
    const [stream, setStream] = useState<any>();
    const [callingId, setCallingId] = useState<number>(0);
    // const [signal, setSignal] = useState<any>();

    const { changeHasCall, signal, socketConversation } = useSocket();

    const myVideo: any = useRef({});
    const userVideo: any = useRef({});
    // const connectionRef: any = useRef({});


    const callUser = async (id: any, conversationId: number) => {
        changeHasCall(true);
        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream: stream,
            config: {
                iceServers: [
                    {
                        urls: "stun:116.118.49.35:3478",
                    },
                    {
                        urls: "turn:116.118.49.35:3478",
                        username: "techzonedigital",
                        credential: "testingTZD",
                    },
                    // {
                    //     urls: "stun:stun.relay.metered.ca:80",
                    // },
                    // {
                    //     urls: "turn:standard.relay.metered.ca:80",
                    //     username: "0399563f26c036a3c218dce1",
                    //     credential: "NxFYRSeC3BMDle4P",
                    // },
                    // {
                    //     urls: "turn:standard.relay.metered.ca:80?transport=tcp",
                    //     username: "0399563f26c036a3c218dce1",
                    //     credential: "NxFYRSeC3BMDle4P",
                    // },
                    // {
                    //     urls: "turn:standard.relay.metered.ca:443",
                    //     username: "0399563f26c036a3c218dce1",
                    //     credential: "NxFYRSeC3BMDle4P",
                    // },
                    // {
                    //     urls: "turns:standard.relay.metered.ca:443?transport=tcp",
                    //     username: "0399563f26c036a3c218dce1",
                    //     credential: "NxFYRSeC3BMDle4P",
                    // },
                ]
            }
        });
        // const peer = new Peer({initiator: type === 'call', stream })
        peer.on('signal', (data) => {
            if (socketConversation) {
                socketConversation.socket.emit('call-user', {
                    userId: id,
                    conversationId: conversationId,
                    signal: data
                });
            }
        });

        peer.on('stream', (currentStream) => {
            userVideo.current.srcObject = currentStream;
        });

        if (socketConversation?.socket) {
            socketConversation.socket.on('call-accepted', (data) => {
                setCallAccepted(true);
                changeHasCall(false);
                peer.signal(data.signal);
            });
        }
        // connectionRef.current = peer;
    };

    const answerCall = (answerUserId: any, conversationId: number) => {
        setCallAccepted(true);
        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream: stream,
            config: {
                iceServers: [
                    {
                        urls: "stun:116.118.49.35:3478",
                    },
                    {
                        urls: "turn:116.118.49.35:3478",
                        username: "techzonedigital",
                        credential: "testingTZD",
                    },
                    // {
                    //     urls: "stun:stun.relay.metered.ca:80",
                    // },
                    // {
                    //     urls: "turn:standard.relay.metered.ca:80",
                    //     username: "0399563f26c036a3c218dce1",
                    //     credential: "NxFYRSeC3BMDle4P",
                    // },
                    // {
                    //     urls: "turn:standard.relay.metered.ca:80?transport=tcp",
                    //     username: "0399563f26c036a3c218dce1",
                    //     credential: "NxFYRSeC3BMDle4P",
                    // },
                    // {
                    //     urls: "turn:standard.relay.metered.ca:443",
                    //     username: "0399563f26c036a3c218dce1",
                    //     credential: "NxFYRSeC3BMDle4P",
                    // },
                    // {
                    //     urls: "turns:standard.relay.metered.ca:443?transport=tcp",
                    //     username: "0399563f26c036a3c218dce1",
                    //     credential: "NxFYRSeC3BMDle4P",
                    // },
                ]
            }
        });

        // const peer = new Peer({initiator: false, stream })

        peer.on('signal', (data) => {
            if (socketConversation) {
                socketConversation.socket.emit("answer-call", {
                    userWasCallId: answerUserId,
                    conversationId: conversationId,
                    signal: data
                })
            }
        });

        peer.on('stream', currentStream => { 
            userVideo.current.srcObject = currentStream;
        });

        peer.signal(signal);

        // connectionRef.current = peer;
        changeHasCall(false);
    };

    const leaveCall = () => {
        changeHasCall(false);
        setCallEnded(true);
        // connectionRef.current.destroy();
        navigate(`/messages/${conversationId}`);
    }


    useEffect(() => {
        const initCamera = async () => {
            const currentStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            setStream(currentStream);
            myVideo.current.srcObject = currentStream;
        }
        initCamera();
    }, []);

    useEffect(() => {
        if (stream && socketConversation) {
            if (type === 'call') {
                callUser(userCallId, 1);
            } else {
                answerCall(userCallId, 1)
            }
        }

    }, [stream, socketConversation]);


    return (
        <div>
            <div className="flex flex-col">
                <div>
                    <video playsInline muted ref={myVideo} autoPlay />
                </div>
                <br />
                <video playsInline ref={userVideo} autoPlay />
            </div>
            <div>
                <span onClick={() => leaveCall()}>Turn Off</span>
            </div>
        </div>
    )
}
