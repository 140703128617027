export class RegExp {
    private static instance: RegExp;

    private constructor() {}

    public static getInstance(): RegExp {
      if (!RegExp.instance) {
        RegExp.instance = new RegExp();
      }
      return RegExp.instance;
    }

    validateEmail(email: string) {
      const re = /^(([^<>()\[\],;:@"]+(\.[^<>()\[\],;:@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    } 

    isValidVietnamesePhoneNumber(phoneNumber: string) {
        const regex = /^(03|05|07|09)\d{8}$/;
        return regex.test(phoneNumber);
    }

    validatePasswordLength(password: string) {
      const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
      return re.test(String(password));
    }
}
