import { Login, Register } from "../../organisms"
import { useSelector } from "react-redux"

const AuthFactory = ({ type }: { type: 'login' | 'register' }) => {
    switch(type) {
        case 'login':
            return <Login />
        case 'register':
            return <Register />
        default:
            return null
    }
}

export const Public = () => {
    const { formType } = useSelector(({auth}) => auth)

    return <div className="w-full h-full flex flex-col gap-4 items-center mt-12">
        <img src='/logo512.png' width={160} height={160} alt='logo' />
        <span className="text-3xl font-bold mb-6">TechZoneDigital</span>
        <AuthFactory type={formType} />
    </div>
}