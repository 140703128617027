import * as types from '../types'

const initialState = {
    profile:  null,
    loadingButton: false,
    loading: true,
    formType: 'login'
}


export const AuthReducer = (state = initialState, action:any) => {
    const { type, payload } = action
    let newProfile: any = state.profile
    switch(type){
        case types.GET_PROFILE_SUCCESS:
        case types.LOGIN_SUCCESS:
        case types.REGISTER_SUCCESS:
            return {
                ...state,
                profile: payload,
                formType: "conversation"
            }
        case types.EDIT_PROFILE_SUCCESS:
            return {
                ...state,
                profile: {
                    ...newProfile,
                    lastAvatar: payload.avatar,
                    fullName: payload.fullName
                }
            }
        case types.LOGOUT_SUCCESS:
            return {
                ...initialState,
                loading: false,
            }
        case types.LOADING_LOGIN_OFF:
        case types.LOADING_LOGIN_ON:
        case types.LOADING_CONVERSATION_ON_GROUP:
        case types.LOADING_CONVERSATION_OFF_GROUP:
        case types.LOADING_EDIT_ON:
        case types.LOADING_EDIT_OFF:
            return {
                ...state,
                loadingButton: payload
            }
        case types.LOADING_PROFILE_OFF:
        case types.LOADING_PROFILE_ON:
            return {
                ...state,
                loading: payload
            }
        case types.SET_FORM_TYPE:
            return {
                ...state,
                formType: payload
            }
        default: 
            return state
    }
}