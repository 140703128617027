import { Icon } from "@iconify/react"
import { MessageStatus } from "../../../services/interfaces"

export const StatusMessage = ({status}: {status: MessageStatus}) => {
    if(status === 'SENT'){
        return <Icon icon="akar-icons:check" />
    }
    if(status === "SEEN") {
        return <Icon icon="akar-icons:double-check" />
    }
    if(status === "SENDING") {
        return <Icon icon="akar-icons:clock" />
    }
    return null
}