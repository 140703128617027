import * as types from '../types'

const initialState = {
    data: new Map(),
    listMessageUUidWaitingByConversationId: new Map()
};

export const SendMessageReducer = (state = initialState, action:any) => {
    const { type, payload } = action
    let newData = new Map(state.data)
    let newMessage = new Map(state.listMessageUUidWaitingByConversationId)
    switch(type){
        case types.SEND_MESSAGE_AWAIT:
            const { uuid, conversationId, data } = payload
            
            newData.set(uuid, {
                ...data,
                medias: data.medias.map((element: any) => element.uuid)
            })
            let newListUuid: any[] = []
            if(newMessage.has(conversationId)) {
                newListUuid = [...newMessage.get(conversationId).listUuid, uuid]
            }
            if(!newMessage.has(conversationId)) {
                newListUuid = [uuid]
            }
            newMessage.set(conversationId, {
                listUuid: newListUuid
            })
            return {
                ...state,
                data: newData,
                listMessageUUidWaitingByConversationId: newMessage
            }
        case types.DELETE_MESSAGE_AWAIT:
            newMessage.get(payload.conversationId).listUuid.splice(newMessage.get(payload.conversationId).listUuid.indexOf(payload.uuid), 1)
            newData.delete(payload.uuid)
            return {
                ...state,
                data: newData,
                listMessageUUidWaitingByConversationId: newMessage
            }
        default:
            return state
    }
}
