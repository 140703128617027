import { IQueryApi } from "../../interfaces";
import repository from "../repository";

export class ChatRepository {
    resource = 'chats';

    async getChat(conversationId: number, query: IQueryApi) {
        return await (await repository.get(`${this.resource}/${conversationId}/messages?page=${query.page ?? 1}&per_page=${query.perPage ?? 50}&keyword=${query.keyword ?? ''}`)).data
    }

    async sendMessage(conversationId: number, data: any) {
        return await (await repository.post(`${this.resource}/${conversationId}/messages`, data)).data
    }
}
