import { SlideTransition } from "../../molecules";
import { useState } from "react";
import { AddFriendToGroup, CreateGroupMolecules } from "../../molecules/createGroup";

export const CreateGroup = () => {
    const [startCreateGroup, setStartCreateGroup] = useState(false)
    const [selectFriend, setSelectFriend] = useState<number[]>([])

    return <div className="w-full h-full max-w-[420px] border-r border-[#dfe1e5] relative z-10">
        <AddFriendToGroup 
            selectFriend={selectFriend}
            switchToCreateGroup={() => setStartCreateGroup(true)}
            handleCheckChange={(friendId, checked) => {
                if(checked) {
                    setSelectFriend([friendId, ...selectFriend])
                }
                if(!checked) {
                    setSelectFriend(selectFriend.filter(e => e !== friendId))
                }
            }}
            removeFriendId={(friendId) => {
                setSelectFriend(selectFriend.filter(e => e !== friendId))
            }}
        />
        <SlideTransition open={startCreateGroup}>
            <CreateGroupMolecules 
                selectFriend={selectFriend}
                comebackToSelectFriend={() => setStartCreateGroup(false)}
            />
        </SlideTransition>
    </div>
}