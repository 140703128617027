import { FactoryRepository } from "../../../services";
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from '../../types'
// import { login } from "../../actions";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const repository = FactoryRepository.get('authentication')

const login = async (email: string, password: string) => {
    return await repository.login(email, password)
}

const handle = async (fullName: string, phone: string, email: string, password: string) => {
    return await repository.register(email, password, fullName, phone)
}

function* register(action: any) {
    try {
        yield put({type: types.LOADING_LOGIN_ON, payload: true})
        const { email, password, fullName, phone } = action.payload
        yield call(handle, fullName, phone, email, password)
        const { accessToken, refreshToken, user } = yield call(login, email, password)
        yield call(Cookies.set, 'access_token', accessToken, { expires: 1/24 })
        yield call(Cookies.set, 'refresh_token', refreshToken, { expires: 7 })
        yield put({type: types.REGISTER_SUCCESS, payload: user})
        toast.success('Đăng ký thành công')
        yield put({type: types.LOADING_LOGIN_OFF, payload: false})
    } catch (error) {
        yield put({type: types.REGISTER_FAILED, payload: null})
        toast.error('Thông tin đã có người đăng ký')
        yield put({type: types.LOADING_LOGIN_OFF, payload: false})
    }
}

export function* watchRegister() {
    yield takeLatest(types.REGISTER_REQUEST, register)
}