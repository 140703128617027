import { AxiosError } from "axios";
import { getRefreshToken } from "../../getToken";
import repository from "../repository";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export const RefreshToken = async () => {
    const refreshToken = getRefreshToken();
    if(refreshToken) {
        try {
            const data = await (await repository.post('/authentication/refresh-token', { refreshToken  })).data
            Cookies.set('access_token', data.accessToken)
            Cookies.set('refresh_token', data.refreshToken)
            return data.accessToken
        } catch (error) {
            toast.error('Phiên đăng nhập đã hết hạn.')
            Cookies.remove('refresh_token')
            return Promise.reject(error)
        }
    }
    else {
        return Promise.reject(AxiosError)
    }
}