import * as types from '../types'

const initialState = {
    data: new Map(),
    listMessageIdsByConversationId: new Map()
  };
export const ChatReducer = (state = initialState, action:any) => {
    const { type, payload } = action
    let newData = new Map(state.data)
    let newMessage = new Map(state.listMessageIdsByConversationId)
    switch(type){
        case types.GET_CHAT_SUCCESS:
            const { conversationId, next, current, data } = payload
            data.forEach((item: any) => {
                if(!newData.has(item.id)) {
                    newData.set(item.id, {
                        ...item,
                        status: 'SENT'
                    })
                }
                return null
            })
            let newListIds = []
            if(newMessage.has(conversationId)) {
                newListIds = [...newMessage.get(conversationId).listIds, ...data.map(({id}: {id: number}) => id)]
                newMessage.set(conversationId, {
                    ...newMessage.get(conversationId),
                    next,
                    nextPage: Number(current) + 1,
                    listIds: newListIds,
                })
            }
            if(!newMessage.has(conversationId)) {
                newListIds = data.map(({id}: {id: number}) => id)
            }
            return {
                ...state,
                data: newData,
                listMessageIdsByConversationId: newMessage
            }
        case types.GET_CHAT_LOADING_ON:
        case types.GET_CHAT_LOADING_OFF:
            if(payload.page === 1) {
                if(!newMessage.has(payload.conversationId)) {
                    newMessage.set(payload.conversationId, {
                        next: true,
                        nextPage: 2,
                        listIds: [],
                        loading: payload.check
                    })
                }
                if(newMessage.has(payload.conversationId)) {
                    newMessage.set(payload.conversationId, {
                        ...newMessage.get(payload.conversationId),
                        loading: payload.check
                    })
                }
            }
            return {
                ...state,
                listMessageIdsByConversationId: newMessage
            }
        case types.SEND_MESSAGE_SUCCESS:
        case types.RECEIVE_MESSAGE:
            newData.set(payload.id, {
                ...payload,
                status: 'SENT'
            })
            if(newMessage.has(payload.conversationId)) {
                
                let conversationData = {...newMessage.get(payload.conversationId)}
                if(newMessage.size > 50) {
                    // xóa phần tử cuối cùng trong list tin nhắn
                    conversationData.listIds.splice(conversationData.listIds.length - 1, 1)
                }
                // thêm phần tử đầu trong list tin nhắn
                conversationData.listIds = [payload.id, ...conversationData.listIds]
                newMessage.set(payload.conversationId, conversationData)
            }
            return {
                ...state,
                data: newData,
                listMessageIdsByConversationId: newMessage
            }
        case types.LOGOUT_SUCCESS:
            return initialState
        default:
            return state
    }
}