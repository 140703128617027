import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { VideoCall } from './components/templates/private/videoCall';
import { SocketProvider } from './socket/socket.provider';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/messages',
    element: <App />
  },
  {
    path: '/messages/:messageId',
    element: <App />
  },
  {
    path: 'videos/:userCallId/:type/:conversationId',
    element: <VideoCall />
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
      <SocketProvider>
        <RouterProvider router={router} />
        <ToastContainer position="bottom-right" />
      </SocketProvider>
    </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
