export const GET_FRIEND_REQUEST_REQUEST = 'GET_FRIEND_REQUEST_REQUEST'
export const GET_FRIEND_REQUEST_SUCCESS = 'GET_FRIEND_REQUEST_SUCCESS'
export const GET_FRIEND_REQUEST_FAILED = 'GET_FRIEND_REQUEST_FAILED'

export const ACCESS_FRIEND_REQUEST_REQUEST = 'ACCESS_FRIEND_REQUEST_REQUEST'
export const ACCESS_FRIEND_REQUEST_SUCCESS = 'ACCESS_FRIEND_REQUEST_SUCCESS'
export const ACCESS_FRIEND_REQUEST_FAILED = 'ACCESS_FRIEND_REQUEST_FAILED'

export const REJECT_FRIEND_REQUEST_REQUEST = 'REJECT_FRIEND_REQUEST_REQUEST'
export const REJECT_FRIEND_REQUEST_SUCCESS = 'REJECT_FRIEND_REQUEST_SUCCESS'
export const REJECT_FRIEND_REQUEST_FAILED = 'REJECT_FRIEND_REQUEST_FAILED'

export const ANSWER_FRIEND_REQUEST = 'ANSWER_FRIEND_REQUEST'

export const LOADING_FRIEND_REQUEST_ON = 'LOADING_FRIEND_REQUEST_ON'
export const LOADING_FRIEND_REQUEST_OFF = 'LOADING_FRIEND_REQUEST_OFF'

export const LOADING_FRIEND_REQUEST_FIRST = 'LOADING_FRIEND_REQUEST_FIRST'
