import { SagaIterator } from "redux-saga";
import { IQueryApi } from "../../../services/interfaces/query";
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from '../../types'
import { FactoryRepository } from "../../../services";

const repository = FactoryRepository.get('friendRequest')

const handle = async (query: IQueryApi) => {
    return await repository.getWithQuery(query)
}

function* getFriendRequest(action: any): SagaIterator {
    const { page } = action.payload
    try {
        yield put({type: types.LOADING_FRIEND_REQUEST_ON, payload: { check: true, page }})
        const result = yield call(handle, action.payload)
        yield put({type: types.GET_FRIEND_REQUEST_SUCCESS, payload: result})
        yield put({type: types.LOADING_FRIEND_REQUEST_OFF, payload: { check: false, page }})
    } catch (error) {
        yield put({type: types.GET_FRIEND_REQUEST_FAILED, payload: null})
        yield put({type: types.LOADING_FRIEND_REQUEST_OFF, payload: { check: false, page }})
    } 
}

export function* watchGetFriendRequest() {
    yield takeLatest(types.GET_FRIEND_REQUEST_REQUEST, getFriendRequest)
}
