import classNames from "classnames";

const colors: { [key: string]: [string, string] } = {
    red: ['#FF845E', '#D45246'],
    orange: ['#FEBB5B', '#F68136'],
    violet: ['#B694F9', '#6C61DF'],
    green: ['#9AD164', '#46BA43'],
    cyan: ['#53edd6', '#28c9b7'],
    blue: ['#5BCBE3', '#359AD4'],
    pink: ['#FF8AAC', '#D95574'],
    archive: ['#B8C2CC', '#9EAAB5']
};

const getColor = (initials: string) => {
    const colorKeys = Object.keys(colors);
    const index = ((initials.charCodeAt(0) || 0) + (initials.charCodeAt(1) || initials.charCodeAt(0) || 0)) % colorKeys.length;
    return colors[colorKeys[index]];
};

export const Avatar = ({name, rounded = true, image}: {name: string, rounded?:boolean, image?: string | null}) => {
    const trimmedName = name.trim();
    const words = trimmedName.split(' ');
    const initials = words.length > 1 ? words[0][0] + words[words.length - 1][0] : words[0][0];
    const [colorStart, colorEnd] = getColor(initials);

    const classes = classNames(
        'flex aspect-square justify-center items-center w-full text-white font-medium overflow-hidden',
        {
            'rounded-full': rounded
        }
    )

    return <div className={classes} style={{ background: `linear-gradient(${colorStart}, ${colorEnd})` }}>
        { 
            image 
            ? <img src={`${process.env.REACT_APP_IMAGE}${image}`} className={`${rounded ? 'rounded-full' : 'w-full'}`} alt='avatar' /> 
            : initials
        }
    </div>
}
