export const FRIEND_SEARCH_REQUEST = 'FRIEND_SEARCH_REQUEST'
export const FRIEND_SEARCH_SUCCESS = 'FRIEND_SEARCH_SUCCESS'
export const FRIEND_SEARCH_FAILED = 'FRIEND_SEARCH_FAILED'

export const ADD_FRIEND_REQUEST = 'ADD_FRIEND_REQUEST'
export const ADD_FRIEND_SUCCESS = 'ADD_FRIEND_SUCCESS'
export const ADD_FRIEND_FAILED = 'ADD_FRIEND_FAILED'

export const LOADING_SEARCH_FRIEND_ON = 'LOADING_SEARCH_FRIEND_ON'
export const LOADING_SEARCH_FRIEND_OFF = 'LOADING_SEARCH_FRIEND_OFF'

export const LOADING_SEARCH_FRIEND_FIRST = 'LOADING_SEARCH_FRIEND_FIRST'