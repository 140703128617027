import { IQueryApi } from "../../interfaces";
import repository from "../repository";

export abstract class BaseRepository {
    resource: string;

    constructor(resource: string) {
        this.resource = resource;
    }

    /**
     * Get With Querys Page
     * @returns 
     */
    async getWithQuery(query: IQueryApi) {
        return await 
            (await repository.get(
                `${this.resource}?page=${query.page ?? 1}&per_page=20&keyword=${query.keyword ?? ''}`
            )).data
    }

    /**
     * Get Method
     */
    async get() {
        return await (await repository.get(`${this.resource}`)).data;
    }

    /**
     * Get id Method
     */
    async getOne(id: number) {
        return await (await repository.get(`${this.resource}/${id}`)).data;
    }

    /**
     * Post Method
     * @param data 
     */
    async create(data: any) {
        return await (await repository.post(`${this.resource}`, data)).data;
    }

    /**
     * Put Method
     * @param data 
     * @param id 
     */
    async update(data: any, id: number) {
        return await repository.put(`${this.resource}/${id}`, data);
    }
}
