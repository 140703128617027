import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getChat } from "../redux/actions"
import { groupMessagesByDate } from "../services/editMessage"

export const BodyChatHook = () => {
    const dispatch = useDispatch()
    const { messageId } = useParams()
    const { data, listMessageIdsByConversationId } = useSelector(({ chat }) => chat)
    const listMessage = listMessageIdsByConversationId.get(Number(messageId)) 
    
    const message = groupMessagesByDate(listMessage?.listIds?.map((element: number) => data.get(element)))
    const loading = listMessage?.loading

    const loadMore = () => {
        if(listMessage?.next) {
            dispatch(getChat(Number(messageId), { page: listMessage?.nextPage }))
        }
    }

    return {
        message,
        loadMore,
        loading
    }
}