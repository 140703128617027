import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from '../../types'
import { FactoryRepository } from "../../../services";

const repository = FactoryRepository.get('friendRequest')

const handle = async (id: number) => {
    return await repository.create({friendId: id})
}

function* addFriend(action: any): SagaIterator {
    try {
        yield call(handle, action.payload)
        yield put({type: types.ADD_FRIEND_SUCCESS, payload: action.payload})
    } catch (error) {
        yield put({type: types.ADD_FRIEND_FAILED})
    }
}

export function* watchAddFriend() {
    yield takeLatest(types.ADD_FRIEND_REQUEST, addFriend)
}
