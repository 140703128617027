import { useSelector } from "react-redux"
import { MeMessage } from "../me"
import ReactHtmlParser from 'react-html-parser';
import { convertToHHMM, replaceEmojiAndLinks } from "../../../../services/editMessage";
import { YouMessage } from "../you";
import { StatusMessage } from "../../../atoms";
import { MessageStatus } from "../../../../services/interfaces";

export const TextMessage = ({
    userId,
    createdAt,
    text,
    status
}: {
    userId: number,
    createdAt: Date,
    text: string,
    status: MessageStatus,
}) => {
    const { profile } = useSelector(({auth}) => auth)

    const IsMe = profile.id === userId

    const textElement = <div style={{ wordBreak: "break-word" }} className={`whitespace-normal max-w-[480px] p-2 rounded-[15px] ${IsMe ? 'rounded-br-none bg-[#e3fee0]' : 'rounded-bl-none bg-white'}  relative`}>
        {ReactHtmlParser(replaceEmojiAndLinks(text))}
        <span className="ml-1 translate-y-1.5 text-[12px] float-right text-[#707579] flex items-center gap-0.5">
            {convertToHHMM(createdAt)}
            <StatusMessage status={status} />
        </span>
    </div>

    if(IsMe) {
        return <MeMessage>
            {textElement}
        </MeMessage>
    }
    return <YouMessage
        userId={userId}
    >
        {textElement}
    </YouMessage>
}