import { SagaIterator } from "redux-saga";
import { FactoryRepository } from "../../../services";
import { IQueryApi } from "../../../services/interfaces";
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from '../../types'

const repository = FactoryRepository.get('conversation')

const handle = async (query: IQueryApi) => {
    return await repository.getWithQuery(query)
}

function* getConversation(action: any): SagaIterator {
    const page = action.payload.page
    try {
        yield put({type: types.GET_CONVERSATION_LOADING_ON, payload: { check: true, page }})
        const result = yield call(handle, action.payload)
        yield put({type: types.GET_CONVERSATION_SUCCESS, payload: result})
        yield put({type: types.GET_CONVERSATION_LOADING_OFF, payload: { check: false, page }})
    } catch (error) {
        yield put({type: types.GET_CONVERSATION_FAILED, payload: null})
        yield put({type: types.GET_CONVERSATION_LOADING_OFF, payload: { check: false, page }})
    }  
}

export function* watchGetConversation() {
    yield takeLatest(types.GET_CONVERSATION_REQUEST, getConversation)
}

