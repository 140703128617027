import { InView } from "react-intersection-observer";
import { BodyChatHook } from "../../../hooks";
import { LottieFile } from "../../atoms";
import { MessageFactory } from "../messages";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MessageStatus, MessageType } from "../../../services/interfaces";
import { Emoji } from "../../../constants";

type MessageList = {
    type: MessageType,
    id: number,
    userId: number,
    createdAt: Date,
    messageContent: { text: string },
    medias: string[],
    status: MessageStatus
}

export const BodyChatComponent = () => {
    const {message, loadMore, loading} = BodyChatHook()
    const { messageId } = useParams()
    const { data, listMessageUUidWaitingByConversationId } = useSelector(({ sendMessage }) => sendMessage)
    const listMessage = listMessageUUidWaitingByConversationId.get(Number(messageId))

    let totalMessages = 0;
    let targetMessageIndex = 16; // the 16th message
    console.log(listMessage)

    return <div className="flex-1 flex flex-col gap-2 w-full items-center">
        {
            loading
            ? <div className="flex items-center justify-center flex-1">
                <LottieFile src='/lottie_file/loading.json' width={200} height={200} />
            </div>
            : <>
                {
                    Object.entries(message || {}).length === 0
                    ? <div className="flex flex-col items-center justify-center flex-1">
                        <span className="text-white text-xl flex items-center gap-2">Đã có thể nhắn tin với nhau rồi <img width={40} height={40} src={`/emoji/${Emoji.get('👋')}.webp`} alt="👋" className="inline-block" /></span>
                    </div>
                    : Object.entries(message || {}).reverse().map(([key, value]: [string, any]) => {
                        return (
                            <div key={key} className="w-full max-w-[728px] h-max px-4 flex flex-col gap-2 relative">
                                <div className="flex justify-center sticky top-[.1875rem] pb-[.3125rem] z-10">
                                    <div className="bg-message-highlighting-color text-white py-[.28125rem] px-[.625rem] rounded-full text-sm">
                                        <span>{key}</span>
                                    </div>
                                </div>
                                {
                                    value?.reverse()?.map(({type, id, userId, createdAt, messageContent: { text }, medias, status}: MessageList, index: number) => {
                                        totalMessages++;
                                        if(totalMessages === targetMessageIndex) {
                                            return (
                                                <InView onChange={(InView) => {
                                                    if(InView) {
                                                        loadMore();
                                                    }
                                                }} key={index}>
                                                    <MessageFactory
                                                        type={type}
                                                        userId={userId}
                                                        createdAt={createdAt}
                                                        text={text}
                                                        medias={medias}
                                                        status={status}
                                                    />
                                                </InView>
                                            )
                                        }
                                        return <MessageFactory
                                            key={index}
                                            type={type}
                                            userId={userId}
                                            createdAt={createdAt}
                                            text={text}
                                            medias={medias}
                                            status={status}
                                        />
                                    })
                                }
                            </div>
                        )
                    })
                }
                {
                    listMessage?.listUuid?.map((element: number) => data.get(element)).map((message: any, index: number) => (
                        <div key={index} className="w-full max-w-[728px] h-max px-4 flex flex-col gap-2 relative">
                            <MessageFactory
                                type={message.type}
                                userId={message.userId}
                                createdAt={message.createdAt}
                                text={message.content.text}
                                medias={message.medias}
                                status={message.status}
                            />
                        </div>
                    ))
                }
            </>
        }

    </div>
}