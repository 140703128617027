import { useSelector } from "react-redux"
import { Button, Input } from "../../../atoms"
import { RegisterHook } from "../../../../hooks"

export const Register = () => {
    const { onSubmit, onChange, error, email, fullName, phone, password, switchLogin } = RegisterHook()
    const { loadingButton } = useSelector(({auth}) => auth)

    return <form onSubmit={onSubmit} className="flex flex-col gap-4 items-center w-[360px]">
    <Input onChange={onChange} name="fullName" label="Nhập họ tên" />
    <Input error={error.phone} onChange={onChange} name="phone" label="Nhập số điện thoại" />
    <Input error={error.email} onChange={onChange} name="email" label="Nhập email" />
    <Input error={error.password} onChange={onChange} name="password" type="password" label="Nhập mật khẩu" />
    <Button disable={
        fullName === '' ||
        phone === '' ||
        email === '' || 
        password === ''
    } loading={loadingButton} type="submit" fullWidth>
        Đăng ký
    </Button>
    <Button onClick={switchLogin} variant="text" fullWidth>
        Đăng nhập
    </Button>
</form>
}