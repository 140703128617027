import axios from "axios";
import { APIURL } from "../../constants";
import { ErrorResponse, configRequest, handleResponse } from "./interceptors";

const repository = axios.create({
    baseURL: APIURL, 
});

repository.interceptors.request.use(configRequest)

repository.interceptors.response.use(handleResponse, ErrorResponse)

export default repository
