export const ImageMimeType = [
    'jpg', 'jpeg', 'png'
]

export const VideoMimeType = [
    'gif', 'mp4', 'avi'
]

export const FileMimeType = [

]