import { ChangeEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { searchFriend } from "../redux/actions"

export const SearchFriendHook = () => {
    const dispatch = useDispatch()
    const { nextPage } = useSelector(({searchFriend}) => searchFriend)
    const [search, setSearch] = useState('')

    useEffect(() => {
        dispatch(searchFriend({ page: 1 }))
    }, [dispatch])

    const HandleSearch = (e: ChangeEvent<HTMLInputElement>) => {console.log(1)
        const value = e.target.value
        setSearch(value)
        dispatch(searchFriend({page: 1, keyword: value }))
    }

    const loadingInfinity = () => {
        dispatch(searchFriend({ page: nextPage, keyword: search }))
    }

    return {
        search,
        HandleSearch,
        loadingInfinity
    }
}