import { ReactNode } from "react"
import { Avatar } from "../../atoms/avatar"

export const ItemList = ({
    name,
    image,
    message,
    className
}: {
    name: string,
    image?: string,
    message?: ReactNode,
    className?: string
}) => {
    return <div className={`${className} text-black hover:bg-[#f3f3f4] cursor-pointer rounded-[10px] h-[4.5rem] max-w-full transition-all flex items-center`}>
        <div className="h-full aspect-square flex items-center justify-center relative">
            <div className="w-[3.375rem] h-[3.375rem]">
                <Avatar name={name} image={image} />  
            </div>
        </div>
        <div className="flex-1 relative h-[46px]">
            <div className="top-0 left-0 w-full absolute flex flex-col">
                <div className="h-[22px] flex">
                    <span className='font-medium'>{name}</span>
                </div>
                <div className='text-[#707579] h-[24px] relative'>
                    {message}
                </div>
            </div>
        </div>
    </div>
}