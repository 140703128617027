import { SagaIterator } from "redux-saga";
import { FactoryRepository } from "../../../services";
import { call, cancelled, put, race, take } from "redux-saga/effects";
import * as types from '../../types'

const chunkSize = 2 * 1024 * 1024;

const repository = FactoryRepository.get('media')

const uploadChunk = async (formData: FormData) => {
    return await repository.chunk(formData)
}

const mergeChunks = async (uuid: string, name: string, conversationId?: number) => {
    return await repository.merge(uuid, name, conversationId)
}

export function* uploadFile(file: File, uuid: string, conversationId?: number): SagaIterator  {
    try {
        const totalSize = file.size;
        let uploadedSize = 0;

        const totalChunks = Math.ceil(totalSize / chunkSize);

        for (let i = 0; i < totalChunks; i++) {
            const start = i * chunkSize;
            const end = Math.min(start + chunkSize, totalSize);
            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append('fileUuid', uuid);
            formData.append('file', chunk);

            // tải lên chunk
            const { stop } = yield race({
                upload: call(uploadChunk, formData),
                // lắng nghe action STOP_UPLOAD_CHUNK
                stop: take((action: any) => action.type === types.STOP_UPLOAD_CHUNK && action.payload.uuid === uuid),
            });

            // cập nhật kích thước đã tải lên
            uploadedSize += chunk.size;
            yield put({ type: types.FILE_UPLOAD_CHUNK, payload: { uploadedSize, uuid } })

            if (stop) {
                // nếu uuid khớp, hủy tải lên
                break;
            }
        }

        // sau khi tất cả các chunk đã được tải lên, gọi API để hợp nhất chúng
        const result = yield call(mergeChunks, uuid, file.name ?? 'avatar-group.jpg', conversationId);
        return {
            mimeType: file.type,
            filename: file.name,
            url: result.data.path,
            size: file.size
        }
    } catch (error) {
        console.error(error);
    } finally {
        if (yield cancelled()) {
            // xóa file khi dừng
        }
    }
}