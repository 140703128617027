import { SagaIterator } from "redux-saga"
import { call, put, select, takeLatest } from "redux-saga/effects";
import * as types from '../../types'
import { FactoryRepository } from "../../../services";

const repository = FactoryRepository.get('friendRequest')

const handle = async (id: number) => {
    return await repository.update({ accept: true }, id)
}

function* accessFriend(action: any): SagaIterator {
    try {
        const { data } = yield select(({ friendRequest }) => friendRequest)
        console.log(data.get(action.payload))
        yield call(handle, action.payload)
        yield put({type: types.ACCESS_FRIEND_REQUEST_SUCCESS, payload: { id: action.payload, data: data.get(action.payload) }})
    } catch (error) {
        yield put({type: types.ACCESS_FRIEND_REQUEST_FAILED})
    }
}

export function* watchAccessFriend() {
    yield takeLatest(types.ACCESS_FRIEND_REQUEST_REQUEST, accessFriend)
}
