import * as types from '../types'

export const StartCall = (sendTo: number) => ({
    type: types.START_CALL,
    payload: { sendTo }
})

export const ReceiveCall = (fromTo: number, singalData: any) => ({
    type: types.RECEIVE_CALL,
    payload: { fromTo, singalData }
})

export const EndCall = () => ({
    type: types.END_CALL
})