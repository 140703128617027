import { IQueryApi } from '../../services/interfaces'
import * as types from '../types'

export const getChat = (conversationId: number, query: IQueryApi) => ({
    type: types.GET_CHAT_REQUEST,
    payload: {conversationId, query}
}) 

export const sendMessageRedux = (conversationId: number, data: any) => ({
    type: types.SEND_MESSAGE_REQUEST,
    payload: {conversationId, data}
})

export const sendMessageAwait = (uuid: string, conversationId: number, data: any) => ({
    type: types.SEND_MESSAGE_AWAIT,
    payload: {uuid, conversationId, data}
})

export const receiveMessageRedux = (data: any) => ({
    type: types.RECEIVE_MESSAGE,
    payload: data
})
