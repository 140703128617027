import { IQueryApi } from '../../services/interfaces'
import * as types from '../types'

export const searchFriend = (query: IQueryApi) => ({
    type: types.FRIEND_SEARCH_REQUEST,
    payload: query
})

export const addFriend = (id: number) => ({
    type: types.ADD_FRIEND_REQUEST,
    payload: id
})
