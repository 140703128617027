import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Avatar } from "../../../atoms/avatar"
import { ReactNode } from "react"
import { Icon } from "../../../../icon"

export const YouMessage = ({ 
    userId, children
}: { 
    userId: number, 
    children: ReactNode
}) => {
    const { messageId } = useParams()
    const { data } = useSelector(({conversation}) => conversation)
    const dataConversation = data.get(Number(messageId))
    const checkGroup = dataConversation?.conversationUsers?.length > 2

    const dataUser = dataConversation?.conversationUsers.find((e: any) => e.userId !== userId)?.user

    return <div className={`relative ${checkGroup && 'ml-[2.875rem]'}`}>
        {
            checkGroup && <div className="-bottom-1 -left-12 absolute w-10 h-10">
                <Avatar name={dataUser?.fullName} image={dataUser?.lastAvatar} /> 
            </div>
        }
        <div className="flex">
            <div style={{ maxWidth: 'calc(100% - 5.5625rem)' }} className="flex flex-col">
                {children}
            </div>
        </div>
        <div className="absolute -bottom-[3px] -left-[10px] w-[11px] h-[20px] text-[1rem] text-white" style={{ transform: 'scale(-1,1)' }}>
            <Icon type="tail" />
        </div>
    </div> 
}