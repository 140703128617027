import { SagaIterator } from "redux-saga";
import { FactoryRepository } from "../../../services";
import { IQueryApi } from "../../../services/interfaces";
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from '../../types'

const repository = FactoryRepository.get('chats')

const handle = async (conversationId: number, query: IQueryApi) => {
    return await repository.getChat(conversationId, query)
}

function* getChat(action: any): SagaIterator {
    const { conversationId, query } = action.payload
    try {
        yield put(({ type: types.GET_CHAT_LOADING_ON, payload: { check: true, page: query.page, conversationId } }))
        const { data, current, next } = yield call(handle, conversationId, query)
        yield put({type: types.GET_CHAT_SUCCESS, payload: { conversationId, data, current, next }})
        yield put(({ type: types.GET_CHAT_LOADING_OFF, payload: { check: false, page: query.page, conversationId } }))
    } catch (error) {
        yield put({type: types.GET_CHAT_FAILED, payload: null})
        yield put(({ type: types.GET_CHAT_LOADING_OFF, payload: { check: false, page: query.page, conversationId } }))
    } 
}

export function* watchGetChat() {
    yield takeLatest(types.GET_CHAT_REQUEST, getChat)
}

