import { IQueryApi } from '../../services/interfaces'
import * as types from '../types'

export const getFriendRequest = (query: IQueryApi) => ({
    type: types.GET_FRIEND_REQUEST_REQUEST,
    payload: query
})

export const accessRequest = (id: number) => ({
    type: types.ACCESS_FRIEND_REQUEST_REQUEST,
    payload: id
})

export const rejectRequest = (id: number) => ({
    type: types.REJECT_FRIEND_REQUEST_REQUEST,
    payload: id
})

export const answerFriendRequest = (id: number, accept: boolean) => ({
    type: types.ANSWER_FRIEND_REQUEST,
    payload: { id, accept }
})
