import { useDispatch } from "react-redux"
import { setFormConversationType } from "../../../redux/actions"
import { useState } from "react"
import { ButtonMenu } from "../../atoms"
import { Icon } from "../../../icon"
import { MenuMolecules } from "./menu"

export const MenuPlus = () => {
    const dispatch = useDispatch()
    const [openMenu, setOpenMenu] = useState(false)
    const listMenu = [
        {
            text: 'Thêm bạn',
            icon: 'userPlus',          
            action: () => dispatch(setFormConversationType("searchFriend"))
        },
        {
            text: 'Tạo nhóm chat',
            icon: 'userGroup',          
            action: () => dispatch(setFormConversationType("createGroup"))
        },
        {
            text: 'Tạo kênh chat (đang phát triển)',
            icon: 'megaPhone',          
            action: () => {}
        },
    ]

    return <>
        <MenuMolecules
            placement="top-right"
            onOutsideClick={() => setOpenMenu(false)}
            open={openMenu} 
            ButtonComponent={<button onClick={() => setOpenMenu(!openMenu)} className="flex items-center justify-center text-white w-[54px] h-[54px]">
                <div className={`${openMenu ? 'rotate-[135deg]' : 'rotate-0'} transition-all`}>
                    <Icon type='plus' />
                </div>
            </button>}
            list={listMenu.map((element: any) => (
                <ButtonMenu key={element.text} onClick={element.action}>
                    <Icon type={element.icon} />
                    {element.text}
                </ButtonMenu>
            ))}
        /> 
    </> 
}