import { ChangeEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getFriendRequest } from "../redux/actions"

export const FriendRequestHook = () => {
    const dispatch = useDispatch()
    const { nextPage } = useSelector(({friendRequest}) => friendRequest)
    const [search, setSearch] = useState('')
    useEffect(() => {
            dispatch(getFriendRequest({ page: 1 }))
    }, [dispatch])

    const HandleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
        dispatch(getFriendRequest({page: 1, keyword: value, }))
    }

    const loadingInfinity = () => {
        dispatch(getFriendRequest({ page: nextPage, keyword: search }))
    }
    return {
        search,
        HandleSearch,
        loadingInfinity
    }
}