import { ClipboardEvent, FormEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Emoji } from "../constants";
import { sendMessageRedux } from "../redux/actions";
import { v4 as uuidv4 } from 'uuid';

export const InputChatHook = () => {
    const [height, setHeight] = useState(37); // Giá trị ban đầu của chiều cao
    const contentEditableDivRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch()
    const { messageId } = useParams()
    const [divValue, setDivValue] = useState('')

    useEffect(() => {
        if(!contentEditableDivRef.current) return
        const currentContentEditableDivRef = contentEditableDivRef.current
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
              if (entry.target === currentContentEditableDivRef) {
                setHeight(entry.contentRect.height + 16)
              }
            }
          });
        resizeObserver.observe(currentContentEditableDivRef);
      
        return () => {
            if (currentContentEditableDivRef) {
              resizeObserver.unobserve(currentContentEditableDivRef);
            }
        };
    }, [])

    useEffect(() => {
        if(!contentEditableDivRef.current) return
        contentEditableDivRef.current.innerText = ''
        setDivValue('')
    }, [messageId])

    const sendMessage = () => {
        if(!contentEditableDivRef.current) return
        contentEditableDivRef.current.innerText = ''
        dispatch(sendMessageRedux(Number(messageId), {
            type: 'TEXT',
            replyId: 17464833,
            content: { text: divValue },
            medias: []
        }))
    }

    const onInput = (e: FormEvent<HTMLDivElement>) => {
        let htmlString = (e.target as HTMLDivElement).innerHTML;
        let parser = new DOMParser();
        let doc = parser.parseFromString(htmlString, 'text/html');
        let textWithEmojis = '';
        for (let node of doc.body.childNodes) {
            if (node.nodeName === 'IMG') {
                let imgNode = node as HTMLImageElement;
                textWithEmojis += imgNode.alt;
            } else if (node.nodeName === 'BR') {
                textWithEmojis += '/n';
            } else {
                textWithEmojis += node.textContent;
            }
        }
        setDivValue(textWithEmojis)
    }

    const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if(e.key === 'Enter' && e.shiftKey) {

        }
        if(e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            // gửi tin nhắn
            sendMessage()
            // xóa nội dung sau khi gửi
            setDivValue('');
            // dispatch(InserTextOrEmoji('', Number(messageId))); 
            (e.target as HTMLDivElement).innerText = ''
        }
    }

    const onPaste = async (e: ClipboardEvent<HTMLDivElement>) => {
        e.preventDefault();
        const originalText = e.clipboardData.getData('text');
        let text = originalText;
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            // Only delete the selected text
            selection.getRangeAt(0).deleteContents();
            // Replace all emojis in the text with img tags
            Emoji.forEach((value, key) => {
                const regex = new RegExp(key, 'g');
                text = text.replace(regex, `<img src="/emoji/${value}.webp" width="18" height="18" class="inline-block" alt="${key}">`);
            });
            // Insert the modified text
            const fragment = document.createRange().createContextualFragment(text);
            selection.getRangeAt(0).insertNode(fragment);
            if(contentEditableDivRef.current) {
                setDivValue(contentEditableDivRef.current.innerText)
            }
        }
        if (e.clipboardData.items) {
            // Loop over all items in the clipboard
            for (let i = 0; i < e.clipboardData.items.length; i++) {
                let item = e.clipboardData.items[i];
                    if (item.type.indexOf("image") === 0) {
                    // If the item is an image, create a blob from it
                    let blob = item.getAsFile();
                    if (blob) {
                        dispatch(sendMessageRedux(Number(messageId), {
                            type: 'FILE',
                            content: { text: 'test' },
                            medias: [{
                                file: blob,
                                uuid: uuidv4()
                            }]
                        }))
                    }
                }
            }
        }
    }

    return {
        height,
        contentEditableDivRef,
        onInput,
        onKeyDown,
        onPaste,
        sendMessage,
        divValue
    }
}