import { ReactNode } from "react"
import { Icon } from "../../../../icon"

export const MeMessage = ({ 
    children
}: { 
    children?: ReactNode
}) => {
    return <div className="relative">
        <div className="flex flex-row-reverse">
            <div style={{ maxWidth: 'calc(100% - 5.5625rem)' }} className="flex flex-col">
                {children}
            </div>
        </div>
        <div className="absolute -bottom-[3px] -right-[8.5px] w-[11px] h-[20px] text-[1rem] text-[#e3fee0]">
            <Icon type="tail" />
        </div>
    </div> 
}