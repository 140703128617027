export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED'

export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST'
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS'
export const EDIT_PROFILE_FAILED = 'EDIT_PROFILE_FAILED'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILED = 'LOGOUT_FAILED'

export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILED = 'REGISTER_FAILED'

export const LOADING_LOGIN_ON = 'LOADING_LOGIN_ON'
export const LOADING_LOGIN_OFF = 'LOADING_LOGIN_OFF'

export const LOADING_PROFILE_ON = 'LOADING_PROFILE_ON'
export const LOADING_PROFILE_OFF = 'LOADING_PROFILE_OFF'

export const LOADING_EDIT_ON = 'LOADING_EDIT_ON'
export const LOADING_EDIT_OFF = 'LOADING_EDIT_OFF'

export const SET_FORM_TYPE = 'SET_FORM_TYPE'

