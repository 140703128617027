import { all } from "redux-saga/effects";
import { watchEditProfile, watchGetProfile, watchLogin, watchLogout, watchRegister } from "./auth";
import { watchCreateConversationFriend, watchCreateConversationGroup, watchGetConversation } from "./conversation";
import { watchGetFriend, watchUnFriend } from "./friend";
import { watchAccessFriend, watchGetFriendRequest, watchRejectFriend } from "./friendRequest";
import { watchAddFriend, watchSearchFriend } from "./searchFriend";
import { watchGetChat, watchSendMessage } from "./chat";

export default function* rootSagas() {
    yield all([
        //auth
        watchLogin(),
        watchGetProfile(),
        watchRegister(),
        watchLogout(),
        watchEditProfile(),
        //conversation
        watchGetConversation(),
        watchCreateConversationFriend(),
        watchCreateConversationGroup(),
        // frient
        watchGetFriend(),
        watchUnFriend(),
        // frient request
        watchGetFriendRequest(),
        watchAccessFriend(),
        watchRejectFriend(),
        // search friend
        watchSearchFriend(),
        watchAddFriend(),
        // chat
        watchGetChat(),
        watchSendMessage(),
    ])
}