import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from "react-responsive"
import { useParams } from "react-router-dom";
import { StartCall, setFormType } from "../../../redux/actions";
import { BackIconBTN } from "../../atoms";
import { Avatar } from "../../atoms/avatar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "../../../icon";
import { useSocket } from "../../../socket/socket.provider";

export const HeaderChat = () => {
    const navigate = useNavigate()
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 926px)'
      })
    const { formType, profile: {id} } = useSelector(({auth}) => auth)
    let { messageId } = useParams();
    const dispatch = useDispatch()
    const [nameConversation, setNameConversation] = useState('')
    const [imageConversation, setImageConversation] = useState(null)
    const [userFriendId, setUserFriend] = useState<number>(0);
    const { data, loading } = useSelector(({conversation}) => conversation)
    const conversationId = data.get(Number(messageId))
    const {hasCall, callingId} = useSocket()

    const videoCall = () => {
        dispatch(StartCall(conversationId?.conversationUsers?.find((e: any) => e?.userId !== id)?.userId))
    }

    useEffect(() => {
        const conversationUsers = conversationId?.conversationUsers
        if(conversationUsers?.length === 2) {
            const findUser = conversationUsers.find((e: any) => e.userId !== id)?.user
            setUserFriend(findUser?.id);
            setNameConversation(findUser?.fullName)
            setImageConversation(findUser?.lastAvatar)
        }
        if(conversationUsers?.length > 2) {
            setNameConversation(conversationId?.name)
            setImageConversation(conversationId?.thumbnail)
        }
    }, [conversationId, id])

    return <div className="md:px-4 bg-white h-14 cursor-pointer flex justify-between" onClick={() => {
        if(formType === "conversation") {
            dispatch(setFormType("chat"))
        }
    }}>
        {
            loading
            ? <div className="animate-pulse flex gap-4">
                <div className="rounded-full bg-light-secondary-text-color h-[50px] w-[50px]"></div>
                <div className="flex-1 space-y-4 py-1 flex flex-col justify-center">
                    <div className="h-2 bg-light-secondary-text-color w-32 rounded" />
                    <div className="h-2 bg-light-secondary-text-color w-8 rounded" />
                </div>
            </div>
            : <div className="flex h-full items-center relative">
                {
                    !isDesktopOrLaptop && <div className="left-0 top-0 h-full aspect-square absolute flex items-center justify-center">
                        <BackIconBTN onClick={() => dispatch(setFormType("conversation"))} />
                    </div>
                }

                <div className={`${isDesktopOrLaptop ? 'pl-0' : 'pl-[55px]'} h-[46px] py-[2px] flex gap-[1.125rem]`}>
                    <div className="h-full aspect-square">
                        {
                            nameConversation !== '' && <Avatar name={nameConversation} image={imageConversation} />
                        }
                    </div>
                    <div className="flex flex-col">
                        {/* <span className="font-medium "
                            onClick={(e) => navigate(`/videos/${userFriendId}/call/${conversationId.id}`)}
                        >{nameConversation}</span> */}
                        <Link to={`/videos/${userFriendId}/call/${conversationId.id}`}>
                            <span className="font-medium "
                                // onClick={(e) => navigate(`/videos/${userFriendId}/call/${conversationId.id}`)}
                            >{nameConversation}</span>
                        </Link>
                        {
                            hasCall && callingId !== 0 && <span onClick={
                                (e) => navigate(`/videos/${callingId}/answer/${conversationId.id}`)
                            }>Accept Call</span>
                        }
                        {/* {
                            conversationId?.typing
                            ? <span className="leading-[1.3125] text-[.875rem] text-[#3390ec]">Đang soạn tin...</span>
                            : <span className="leading-[1.3125] text-[.875rem] text-[#707579]">Truy cập 1 phút trước</span>
                        } */}
                    </div>
                </div>
            </div>
        }
        <div className="flex gap-1 items-center pr-2">
            {/* call width video */}
            <button onClick={videoCall} className="w-10 h-10 flex justify-center items-center transition-all hover:bg-light-secondary-text-color text-[#707579] rounded-full">
                <Icon type="video" />
            </button>
        </div>
    </div>
}