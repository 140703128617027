import { FactoryRepository } from "../../../services";
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from '../../types'
import { SagaIterator } from "redux-saga";
import { getAccessToken, getRefreshToken } from "../../../services/getToken";

const repository = FactoryRepository.get('authentication')

const handle = async () => {
    return await repository.profile()
}

function* getProfile(): SagaIterator {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    if(accessToken || refreshToken) {
        try {
            yield put({type: types.LOADING_PROFILE_ON, payload: true})
            const profile  = yield call(handle)
            yield put({type: types.GET_PROFILE_SUCCESS, payload: profile})
            yield put({type: types.LOADING_PROFILE_OFF, payload: false})
        } catch (error) {
            yield put({type: types.GET_PROFILE_FAILED, payload: null})
            yield put({type: types.LOADING_PROFILE_OFF, payload: false})
        }
    }
    else {
        yield put({type: types.GET_PROFILE_FAILED, payload: null})
        yield put({type: types.LOADING_PROFILE_OFF, payload: false})
    }
    
}

export function* watchGetProfile() {
    yield takeLatest(types.GET_PROFILE_REQUEST, getProfile)
}