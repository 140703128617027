import { Menu, Transition } from "@headlessui/react"
import { Fragment, useState } from "react"
import { Emoji } from "../../../constants"
import { Icon } from "../../../icon"

export const MenuEmoji2 = ({onImageClick}: {onImageClick: (unicode: string) => void}) => {
    const [openMenuEmoji, setOpenMenuEmoji] = useState(false)

    return <Menu 
            as='div' 
            className='relative'
            onMouseEnter={() => setOpenMenuEmoji(true)}
            onMouseLeave={() => setOpenMenuEmoji(false)}
        >
        <Menu.Button className={`mb-2 flex items-center justify-center ${openMenuEmoji ? 'text-[#3390ec]' : 'text-[#707579] hover:text-[#3390ec]'} transition-all`}>
            <Icon type="smile" />
        </Menu.Button>
        <Transition
            show={openMenuEmoji}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-200"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className='absolute max-w-[300px] w-max outline-none z-50 px-1.5 bottom-full left-0 pb-1'>
                <div className="bg-white/90 backdrop-blur flex flex-col rounded-lg py-2" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, .15)' }}>
                    <div className="max-h-[26.25rem] w-full bg-menu-background-color flex flex-wrap gap-2 p-4">
                        {
                            [...Emoji].map(([key, value]) => (
                                <div onClick={() => onImageClick(key)} key={value} className="w-[42px] h-[42px] flex items-center justify-center hover:bg-light-secondary-text-color transition-all rounded-lg cursor-pointer">
                                    <img width={35} height={35} alt={key} src={`/emoji/${value}.webp`} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Menu.Items>
        </Transition>
    </Menu>
}