import { useDispatch, useSelector } from "react-redux";
import { ContactHook } from "../../../hooks";
import { Icon } from "../../../icon";
import { InfinityScroll } from "../InfinityScroll";
import { ItemList } from "../ItemList";
import { Avatar } from "../../atoms/avatar";
import { BackIconBTN, Search } from "../../atoms";
import { animateScroll as scroll } from 'react-scroll';
import { setFormConversationType } from "../../../redux/actions";

export const AddFriendToGroup = ({
    selectFriend,
    switchToCreateGroup,
    handleCheckChange,
    removeFriendId
}: {
    selectFriend: number[],
    switchToCreateGroup: () => void,
    handleCheckChange: (friendId: number, checked: boolean) => void,
    removeFriendId: (friendId: number) => void
}) => {
    const { search, HandleSearch, loadingInfinity } = ContactHook()
    const { data } = useSelector(({friend}) => friend)
    const dispatch = useDispatch()

    console.log(selectFriend)

    return <div className="top-0 left-0 w-full h-full z-[1] flex flex-col bg-white absolute">
        <div className=" px-4">
            <div className="flex h-full items-center gap-2 py-2">
                <div className="h-10 w-10">
                    <BackIconBTN onClick={() => dispatch(setFormConversationType(null))} />
                </div>
                <span className="font-medium text-lg">Thêm bạn bè vào nhóm</span>
            </div>
        </div>
        <div className="h-[56px]">
            <Search value={search} onChange={(e) => {
                scroll.scrollToTop({ containerId: "left", duration: 0 });
                HandleSearch(e)
            } } />
        </div>
        {
            selectFriend.length > 0 && <div className="flex flex-wrap gap-2 p-2">
                {
                    selectFriend.map(element => (
                        <div key={element} className="relative w-8 h-8">
                            <Avatar name={data.get(element).user.fullName} image={data.get(element).user.lastAvatar} />
                            <button onClick={() => removeFriendId(element)} className="-top-1.5 -right-1.5 absolute p-[2px] shadow rounded-full bg-white transition-all">
                                <Icon type="Xmark" className="!w-3.5 !h-3.5" />
                            </button>
                        </div>
                    ))
                }
            </div>
        }
        <div className="h-3 bg-[#f4f4f5]" />
        <div className="flex-1 relative">
            <div id='left' className="top-0 left-0 w-full h-full absolute overflow-auto scrollbar-show py-2">
                <InfinityScroll
                    keyRedux="friend" 
                    handle={loadingInfinity} 
                    text='Bạn chưa có ai trong danh bạ'
                    item={
                        value => {
                            const { user: { fullName, lastAvatar }, friendId } = data.get(value)
                            return <div className="w-full relative">
                                <ItemList name={fullName} image={lastAvatar} className="pl-10" />
                                <div className="left-2 top-1/2 flex items-center justify-center -translate-y-1/2 absolute cursor-pointer">
                                    <input type="checkbox" checked={selectFriend.includes(friendId)} onChange={(e) => handleCheckChange(friendId, e.target.checked)} className="z-[-1] cursor-pointer w-5 h-5" />
                                </div>
                            </div>
                        } 
                    }
                />
            </div>
        </div>
        {
            selectFriend.length > 1 && <button onClick={switchToCreateGroup} className="h-[54px] w-[54px] flex items-center justify-center rounded-full bg-[#3390ec] hover:bg-dark-primary-color z-20 right-5 bottom-5 absolute text-white">
                <Icon type="arrowRight" />
            </button>
        }
    </div>
}