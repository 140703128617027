import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from '../../types'
import { FactoryRepository } from "../../../services";

const repository = FactoryRepository.get('friendRequest')

const handle = async (id: number) => {
    return await repository.update({ accept: false }, id)
}

function* rejectFriend(action: any): SagaIterator {
    try {
        yield call(handle, action.payload)
        yield put({type: types.REJECT_FRIEND_REQUEST_SUCCESS, payload: { id: action.payload }})
    } catch (error) {
        yield put({type: types.REJECT_FRIEND_REQUEST_FAILED})
    }
}

export function* watchRejectFriend() {
    yield takeLatest(types.REJECT_FRIEND_REQUEST_REQUEST, rejectFriend)
}
