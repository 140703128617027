// import { FactoryRepository } from "../../../services";
import { 
    // call, 
    put, takeLatest } from "redux-saga/effects";
import * as types from '../../types'
import Cookies from "js-cookie";
import { SagaIterator } from "redux-saga";

// const repository = FactoryRepository.get('authentication')

// const handle = async () => {
//     return await repository.logout()
// }

function* logout(): SagaIterator {
    Cookies.remove('access_token')
    Cookies.remove('refresh_token')
    yield put({type: types.LOGOUT_SUCCESS, payload: null})
    // try {
    //     yield call(handle)
    //     yield put({type: types.LOGOUT_SUCCESS, payload: null})
    // } catch (error) {
    //     yield put({type: types.LOGOUT_FAILED, payload: null})
    // }
}

export function* watchLogout() {
    yield takeLatest(types.LOGOUT_REQUEST, logout)
}