import { Socket, io } from "socket.io-client";
import { getAccessToken } from "../services/getToken";
import { pathSocket, socketURL } from "../constants";
import { ReactNode, createContext, useContext, useEffect, useState, useRef } from "react";
import { RefreshToken } from "../services/repositories/refreshToken";
import { MessageSocket } from "./message.socket";
import { useSelector } from "react-redux";
import { UserSocket } from "./user.socket";

// Create a context
const SocketContext = createContext<{
    socketMessage: { socket: Socket, path: string } | null,
    socketUser: { socket: Socket, path: string } | null,
    socketConversation: { socket: Socket, path: string } | null,
    hasCall: boolean,
    callingId: number,
    signal: any,
    changeSignal: (signal: any) => void;
    changeHasCall: (val: boolean) => void,
    changeCallingId: (id: number) => void,
}>({
    socketMessage: null,
    socketUser: null,
    socketConversation: null,
    hasCall: false,
    callingId: 0,
    signal: null,
    changeSignal: (signal: any) => {},
    changeHasCall: (val: boolean) => {},
    changeCallingId: (id: number) => {}
});

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({children}: {children: ReactNode}) => {
    const [socketMessage, setSocketMessage] = useState<{ socket: Socket, path: string } | null>(null);
    const [socketUser, setSocketUser] = useState<{ socket: Socket, path: string } | null>(null);
    const [socketConversation, setSocketConversation] = useState<{ socket: Socket, path: string } | null>(null);
    const { profile } = useSelector(({auth}) => auth)

    // For testing video call
    const [hasCall, setHasCall] = useState<boolean>(false);
    const [callingId, setCallingId] = useState<number>(0);
    const [signal, setSignal] = useState<any>();
    const changeHasCall = (val: boolean) => setHasCall(val);
    const changeCallingId = (id: number) => setCallingId(id);
    const changeSignal = (signal: any) => setSignal(signal);


    const { data } = useSelector(({sendMessage}) => sendMessage)
    // const dispatch = useDispatch()

    const { receiveMessage, typing } = MessageSocket()
    const { addFriend, answerFriendRequest, friendOnline } = UserSocket()
    const accessToken = getAccessToken();

    async function createAndConnectSocket(path: string) {
        if(data.size > 0) {
            // bắt đầu gửi các tin nhắn đang chờ gửi lên server
        }
        const socket = io(
            `${socketURL}/${path}`,
            {
                path: `${pathSocket}/${path}`,
                transports: ["websocket"],
                withCredentials: true,
                auth: {
                    authorization: `Bearer ${accessToken}`
                }
            }
        );
        socket.on('connect', () => console.log(`connected ${path}`));
        socket.on('disconnect', async () => {
            try {
                await RefreshToken();
                createAndConnectSocket(path);
            } catch (error) {
                return Promise.reject(error);
            }
        })
        return { socket, path };
    }

    useEffect(() => {
        const doAsync = async () => {
            const socketMessage = await createAndConnectSocket('messages');
            const socketUser = await createAndConnectSocket('users');
            const socketConversation = await createAndConnectSocket('conversations');

            socketMessage.socket.on('receive-messages', receiveMessage)
            socketMessage.socket.on('typing', typing)
            socketUser.socket.on('add-friend-request', addFriend)
            socketUser.socket.on('answer-friend-request', answerFriendRequest)
            socketUser.socket.on('friend-onlines', friendOnline)

            socketConversation.socket.on('call-user', (data) => {
                console.log(data, '~~~ on call-user');
                changeSignal(data.signal);
            });
            socketConversation.socket.on('received-call', (data) => {
                console.log(data, '~~~ on received-call');
                changeHasCall(true);
                setCallingId(data.user.id);
                changeSignal(data.signal);
            });
            socketConversation.socket.on('answer-call', (data) => {
                console.log(data, '~~~ on answer-call');
            });

            setSocketMessage(socketMessage);
            setSocketUser(socketUser);
            setSocketConversation(socketConversation);

            // Cleanup function
            return () => {
                socketMessage.socket.off('receive-messages', receiveMessage)
                socketMessage.socket.off('typing', typing)
            };
        };
        if(profile) {
            doAsync();
        }
    }, [profile]);
    return <SocketContext.Provider value={{
        socketMessage,
        socketUser,
        socketConversation,
        hasCall,
        callingId,
        changeHasCall,
        changeCallingId,
        signal,
        changeSignal
    }}>
        {children}
    </SocketContext.Provider>
}