import { FactoryRepository } from "../../../services";
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from '../../types'
import Cookies from "js-cookie";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { getConversation } from "../../actions";

const repository = FactoryRepository.get('authentication')

const handle = async (email: string, password: string) => {
    return await repository.login(email, password)
}

function* login(action: any) {
    try {
        yield put({type: types.LOADING_LOGIN_ON, payload: true})
        const { email, password } = action.payload
        const { accessToken, refreshToken, user } = yield call(handle, email, password)
        yield call(Cookies.set, 'access_token', accessToken, { expires: 1/24 })
        yield call(Cookies.set, 'refresh_token', refreshToken, { expires: 7 })
        yield put({type: types.LOGIN_SUCCESS, payload: user})
        toast.success('Đăng nhập thành công')
        yield put(getConversation({ page: 1 }))
        yield put({type: types.LOADING_LOGIN_OFF, payload: false})
    } catch (error) {
        const err = error as AxiosError
        if(err?.response?.status === 401 || err?.response?.status === 400) {
            yield call(toast.error, 'Sai tài khoản hoặc mật khẩu')
        }
        yield put({type: types.LOGIN_FAILED, payload: null})
        yield put({type: types.LOADING_LOGIN_OFF, payload: false})
    }
}

export function* watchLogin() {
    yield takeLatest(types.LOGIN_REQUEST, login)
}