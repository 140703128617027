import { ChangeEvent, useState } from "react"
import { Icon } from "../../../../icon";

export const Search = ({
    value,
    onChange
}: {
    value?: string,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void 
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => {
        setIsFocused(true);
      };
    
    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleClear = () => {
        if(onChange) {
            onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
        }
    };

    return <div className='px-1.5 relative flex-1 h-[42px] items-center transition-all mx-1.5'>
        <div className={`top-0 left-0 absolute w-full h-full ${isFocused ? 'border-2 border-[#3390ec]' : 'border border-[#dfe1e5]'} rounded-full transition`} />
        <span className={`${isFocused ? 'text-[#3390ec]' : 'text-[#707579] opacity-60'} transition w-6 h-6 absolute top-1/2 -translate-y-1/2  left-3.5`}>
            <Icon type="magnifyingGlass" />
        </span>
        {
            value === '' 
            ? <span className='text-[#9e9e9e] left-10 ml-1.5 top-1/2 -translate-y-1/2 absolute'>Tìm kiếm</span>
            : <button onClick={handleClear} className="text-[#707579] h-full aspect-square rounded-full hover:bg-light-secondary-text-color transition-all top-0 right-0 absolute z-20 flex justify-center items-center">
                <Icon type="Xmark" />
            </button>
        }
        <input value={value} onChange={onChange} className="w-full h-full outline-none bg-transparent pl-10 relative z-10" 
            onFocus={handleFocus}
            onBlur={handleBlur}
        />
    </div>
}