import { useDispatch, useSelector } from "react-redux"
import { BackIconBTN } from "../../atoms"
import { Avatar } from "../../atoms/avatar"
import { toast } from "react-toastify"
import { setFormConversationType, setFormSettingType } from "../../../redux/actions"
import { Icon } from "../../../icon"
import { SlideTransition } from "../../molecules"
import { SettingFactory } from "../../molecules/setting"
import { SettingType } from "../../../services/interfaces/setting"

export const Setting = () => {
    const { profile } = useSelector(({auth}) => auth)
    const dispatch = useDispatch()
    const { setting } = useSelector(({conversation}) => conversation)
    const data: SettingType[] = ['editProfile', 'changePassword']
    console.log(profile)

    // Hàm để định dạng số điện thoại
    const formatPhoneNumber = (phone: string) => {
        return phone.replace('+84', '+84 ');
    }

    // Hàm để sao chép số điện thoại
    const copyPhoneNumber = () => {
        navigator.clipboard.writeText(profile.phone);
        toast('Số điện thoại đã được sao chép!');
    }

    console.log(profile)

    return <div className="w-full h-full relative">
        <div className="flex flex-col gap-3 top-0 left-0 w-full h-full z-[1] bg-[#f4f4f5] absolute">
            <div className="flex flex-col bg-white">
                <div className="h-[3.5rem] flex justify-between items-center gap-5 px-4">
                    <div className="flex items-center gap-5">
                        <BackIconBTN onClick={() => dispatch(setFormConversationType(null))} />
                        <span className="text-xl font-bold">Cài đặt</span>
                    </div>
                    <button onClick={() => dispatch(setFormSettingType('editProfile'))} className="hover:bg-light-secondary-text-color w-10 h-10 rounded-full flex items-center justify-center">
                        <Icon type="pencil" className="!w-5 !h-5" />
                    </button>
                </div>
                <div className="aspect-video w-full relative text-5xl">
                    <Avatar rounded={false} name={profile.fullName} image={profile.lastAvatar} />
                </div>
                <div className="py-2 w-full" style={{ boxShadow: '0 1px 3px 0 rgba(0, 0, 0, .06)' }}>
                    <div className="px-2 w-full">
                        <div onClick={copyPhoneNumber} className="w-full pl-16 h-[3.5rem] flex flex-col justify-center cursor-pointer hover:bg-light-secondary-text-color transition-all rounded-[10px] relative">
                            <div className="top-1/2 -translate-y-1/2 left-1.5 absolute">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                                </svg>
                            </div>
                            <span>{ formatPhoneNumber(profile.phone) }</span>
                            <span className="text-[#707579] text-sm">Số điện thoại</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-1 bg-white" />
        </div>
        {
            data.map(element => (
                <SlideTransition key={element} open={setting === element}>
                    <SettingFactory type={element} />
                </SlideTransition>
            ))
        }
    </div> 
    
}