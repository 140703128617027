import { SettingType } from "../../../services/interfaces/setting";
import { EditProfile } from "./editProfile";

export const SettingFactory = ({type}: {type: SettingType}) => {
    switch(type) {
        case 'editProfile':
            return <EditProfile />
        default:
            return null
    }
}