import { BackIconBTN, Button, Search } from "../../atoms";
import { animateScroll as scroll } from 'react-scroll';
import { InfinityScroll } from "../../molecules";
import { FriendRequestHook } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { accessRequest, rejectRequest, setFormConversationType } from "../../../redux/actions";
import { ItemList } from "../../molecules/ItemList";

export const FriendRequest = () => {
    const { search, HandleSearch, loadingInfinity } = FriendRequestHook()
    const { data } = useSelector(({friendRequest}) => friendRequest)
    const dispatch = useDispatch()
    return <div className="w-full h-full max-w-[420px] border-r border-[#dfe1e5] relative z-10">
        <div className="top-0 left-0 w-full h-full z-[1] flex flex-col bg-white absolute">
            <div className="h-[56px] px-4">
                <div className="flex h-full items-center">
                    <div className="h-10 w-10">
                        <BackIconBTN onClick={() => dispatch(setFormConversationType(null))} />
                    </div>
                    <Search value={search} onChange={(e) => {
                        scroll.scrollToTop({ containerId: "left", duration: 0 });
                        HandleSearch(e)
                    } } />
                </div>
            </div>
            <div className="flex-1 relative">
                <div id='left' className="top-0 left-0 w-full h-full absolute overflow-auto scrollbar-show">
                    <InfinityScroll
                        keyRedux="friendRequest" 
                        handle={loadingInfinity} 
                        text='Bạn không có lời mời kết bạn nào'
                        item={
                            value => {
                                const { user: { fullName, lastAvatar }, id } = data.get(value)
                                return <div className="relative w-full">
                                    <ItemList
                                        name={fullName}
                                        image={lastAvatar}
                                    />
                                    <div className="right-2 top-1/2 -translate-y-1/2 flex gap-2 absolute">
                                        <Button onClick={() => dispatch(accessRequest(id))} className="px-4 !h-10">
                                            Đồng ý 
                                        </Button>
                                        <Button onClick={() => dispatch(rejectRequest(id))} variant="text" className="px-4 !h-10">
                                            Từ chối
                                        </Button>
                                    </div>
                                </div> 
                            } 
                        }
                    />
                </div>
            </div>
        </div>
    </div>
}