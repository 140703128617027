import { ChangeEvent, FormEvent, useState } from "react"
import { useDispatch } from "react-redux"
import { login, setFormType } from "../redux/actions"

export const LoginHook = () => {
    const dispatch = useDispatch()

    const [data, setData] = useState({
        email: '',
        password: ''
    })

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target
        setData({...data, [name]: value})
    }
    const { email, password } = data
    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        dispatch(login(email, password))
    }

    const switchRegister = () => dispatch(setFormType('register'))

    return {
        onChange,
        onSubmit,
        email,
        password,
        switchRegister
    }
}