import { InputChatHook } from "../../../hooks"
import { Icon } from "../../../icon"
import { MenuEmoji2, MenuMedia } from "../menu2"
import { ZoomTransition } from "../ZoomTransition"
import { Emoji } from "../../../constants"

export const InputChat = ({scrollToBottom}: {scrollToBottom: () => void}) => {
    const { height, onInput, onKeyDown, onPaste, contentEditableDivRef, sendMessage, divValue } = InputChatHook()
    // const [contentEditable, setContentEditable] = useState(true)

    const clickPaperAirplane = () => {
        scrollToBottom()
        if(divValue !== '') {
            sendMessage()
        }
        if(divValue === '') {
            
        }
    }
    return <div className="flex gap-2 w-full h-auto flex-1 items-end transition-all relative">
        <div className="min-h-[3.375rem] max-h-[30rem] flex-1 flex flex-col items-start relative">
            <div className="w-full transition-all outline-none flex-1 flex items-end px-2 py-[.3125rem] bg-white rounded-2xl rounded-br-none relative z-20">
                <div className="relative h-[2.125rem] aspect-square flex items-center justify-center">
                    <MenuEmoji2 onImageClick={(unicode) => {
                        if (!contentEditableDivRef.current) return
                        contentEditableDivRef.current.focus();
                        const selection = window.getSelection()
                        if (selection && selection.rangeCount > 0) {
                            const range = selection.getRangeAt(0);
                            const img = document.createElement('img');
                            img.src = `/emoji/${Emoji.get(unicode)}.webp`
                            img.width = 18
                            img.height = 18
                            img.className = 'inline-block' 
                            img.alt =`${unicode}`
                            range.insertNode(img);
                            range.collapse(false);
                            const newRange = document.createRange();
                            newRange.setStartAfter(img);
                            newRange.setEndAfter(img);
                            selection.removeAllRanges();
                            selection.addRange(newRange);
                        }
                        const event = new Event('input', { bubbles: true });
                        contentEditableDivRef.current.dispatchEvent(event);
                    }} />
                </div>
                <div style={{ height: height }} className="flex-1 relative outline-none px-2 flex flex-col items-center transition-all">
                    <div className="top-0 left-0 h-full absolute w-full overflow-hidden">
                        <div
                            onInput={onInput}
                            onKeyDown={onKeyDown}
                            onPaste={onPaste}
                            ref={contentEditableDivRef}
                            className="max-h-[27.5rem] min-h-[37px] flex-1 break-word leading-[1.3125] pb-3 pt-1 w-full outline-none caret-[#3390ec] overflow-auto scrollbar-show-chat" 
                            contentEditable
                            suppressContentEditableWarning
                        />
                    </div>
                </div>
                <div className="w-[9px] h-[18px] text-[1rem] absolute -bottom-[1px] -right-[9px] text-white">
                    <Icon type="tail" />
                </div>
                <MenuMedia />
            </div>
        </div>
        <div className="relative">
            <div onClick={clickPaperAirplane} className="w-[54px] h-[54px] rounded-full bg-[#3390ec] hover:bg-dark-primary-color transition-all text-white cursor-pointer relative z-10">
                <ZoomTransition open={divValue === ''}>
                    <div className="top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute">
                        <Icon type="micro" />
                    </div>
                </ZoomTransition>
                <ZoomTransition open={divValue !== ''}>
                    <div className="top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute">
                        <Icon type="paperAirplane" />
                    </div>
                </ZoomTransition>
            </div> 
        </div>
    </div>
}