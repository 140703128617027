import { SagaIterator } from "redux-saga";
import { FactoryRepository } from "../../../services";
import { call, put, select, takeLatest, all } from "redux-saga/effects";
import * as types from '../../types'
import { v4 as uuidv4 } from 'uuid';
import { uploadFile } from "../file";

const repository = FactoryRepository.get('chats')

const handle = async (conversationId: number, data: any) => {
    return await repository.sendMessage(conversationId, data)
}

function* sendMessage(action: any): SagaIterator {
    const { conversationId, data } = action.payload
    const uuidMessage = uuidv4()
    if(data.medias.length === 0) {
        try {
            const { profile } = yield select(({auth}) => auth)
            yield put({type: types.SEND_MESSAGE_AWAIT, payload: {
                uuid: uuidMessage,
                conversationId,
                data: {
                    ...data,
                    createdAt: new Date(),
                    userId: profile.id,
                    status: 'SENDING',
                    medias: []
                }
            }})
            const message = yield call(handle, conversationId, data)
            // xóa tin nhắn chờ
            yield put({type: types.DELETE_MESSAGE_AWAIT, payload: { uuid: uuidMessage, conversationId }})
            // thêm tin nhắn gửi thành công từ server
            yield put({type: types.SEND_MESSAGE_SUCCESS, payload: message })
        } catch (error) {
            yield put({type: types.SEND_MESSAGE_FAILED, payload: null})
        } 
    }
    if(data.medias.length > 0) {
        const { profile } = yield select(({auth}) => auth)
        yield put({type: types.SEND_MESSAGE_AWAIT, payload: {
            uuid: uuidMessage,
            conversationId,
            data: {
                ...data,
                createdAt: new Date(),
                userId: profile.id,
                status: 'SENDING',
            }
            
        }})
        //upload chunk tất cả medias
        const links = yield all(data.medias.map((element: any) => uploadFile(element.file, element.uuid, conversationId)))
        // gửi tin nhắn sau khi tất cả các file đã được merge
        const message = yield call(handle, conversationId, {
            ...data,
            medias: links.map((element: any) => ({
                contentType: element.mimeType,
                text: null,
                mimeType: element.mimeType,
                filename: element.filename,
                url: element.url,
                size: element.size
            }))
        })
        // xóa tin nhắn chờ
        yield put({type: types.DELETE_MESSAGE_AWAIT, payload: { uuid: uuidMessage, conversationId }})
        // thêm tin nhắn gửi thành công từ server
        yield put({type: types.SEND_MESSAGE_SUCCESS, payload: message })
    }
}

export function* watchSendMessage() {
    yield takeLatest(types.SEND_MESSAGE_REQUEST, sendMessage)
}

