import { Transition } from "@headlessui/react"
import { Fragment, ReactNode } from "react"

export const ZoomTransition = ({open, children}: {open: boolean, children: ReactNode}) => {
    return <Transition
        show={open}
        as={Fragment}
        enter="transition ease-out duration-400"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-400"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
    >
        {children}
    </Transition>
}