import { useDispatch, useSelector } from "react-redux"
import { checkTyping, receiveMessageRedux } from "../redux/actions"
import { useParams } from "react-router-dom"

export const MessageSocket = () => {
    const dispatch = useDispatch()
    const { messageId } = useParams()
    const { profile } = useSelector(({ auth }) => auth)
    const receiveMessage = (e: any) => {
        if(profile?.id !== e?.user?.id) {
            dispatch(receiveMessageRedux(e))
        }
    }
    const typing = (e: any) => {
        if(profile.id !== e.user.id) {
            dispatch(checkTyping(e.onProcess, Number(messageId)))
        }
    }

    return { receiveMessage, typing }
}