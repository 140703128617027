import { ChangeEvent, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor"
import { Icon } from "../../../icon";

export const EditAvatar = ({
    image,
    result
}: {
    image: string,
    result: (e: string) => void
}) => {
    const editorRef = useRef<AvatarEditor>(null);
    const [scale, setScale] = useState(1.2);

    const handleScaleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setScale(parseFloat(event.target.value));
    }

    const handlePreview = () => {
        if (editorRef.current) {
          const canvas = editorRef.current.getImage();
          result(canvas.toDataURL());
        }
      }

    return <div className="flex flex-col gap-2">
        <div>
            <AvatarEditor
                ref={editorRef}
                image={image}
                width={250}
                height={250}
                border={50}
                borderRadius={999}
                color={[255, 255, 255, 0.6]} // Màu nền
                scale={scale}
            />
        </div>
        <input
            name="scale"
            type="range"
            onChange={handleScaleChange}
            min="1"
            max="2"
            step="0.01"
            defaultValue="1.2"
        />
        <div className="flex justify-end w-full px-4">
            <button onClick={handlePreview} className="h-[54px] w-[54px] flex items-center justify-center rounded-full bg-[#3390ec] hover:bg-dark-primary-color z-20 text-white">
                <Icon type="check" />
            </button>
        </div>
    </div>
}