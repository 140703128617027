import { IQueryApi } from '../../services/interfaces'
import * as types from '../types'

export const getFriend = (query: IQueryApi) => ({
    type: types.GET_FRIEND_REQUEST,
    payload: query
})

export const unFriend = (friendId: number) => ({
    type: types.UN_FRIEND_REQUEST,
    payload: friendId
})
