import { BackIconBTN, Button, Search } from "../../atoms";
import { animateScroll as scroll } from 'react-scroll';
import { InfinityScroll } from "../../molecules";
import { SearchFriendHook } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { addFriend, setFormConversationType } from "../../../redux/actions";
import { ItemList } from "../../molecules/ItemList";

export const SearchFriend = () => {
    const { search, HandleSearch, loadingInfinity } = SearchFriendHook()
    const { data } = useSelector(({searchFriend}) => searchFriend)
    const dispatch = useDispatch()

    return <div className="w-full h-full max-w-[420px] border-r border-[#dfe1e5] relative z-10">
        <div className="top-0 left-0 w-full h-full z-[1] flex flex-col bg-white absolute">
            <div className="h-[56px] px-4">
                <div className="flex h-full items-center">
                    <div className="h-10 w-10">
                        <BackIconBTN onClick={() => dispatch(setFormConversationType(null))} />
                    </div>
                    <Search value={search} onChange={(e) => {
                        scroll.scrollToTop({ containerId: "left", duration: 0 });
                        HandleSearch(e)
                    } } />
                </div>
            </div>
            <div className="flex-1 relative">
                <div id='left' className="top-0 left-0 w-full h-full absolute overflow-auto scrollbar-show">
                    <InfinityScroll
                        keyRedux="searchFriend" 
                        handle={loadingInfinity} 
                        text='Hiện chưa có ai tham gia ứng dụng này ngoài bạn'
                        item={
                            value => {
                                const { fullName, isFriend, id } = data.get(value)
                                if(!isFriend) {
                                    return <div className="w-full relative">
                                        <ItemList 
                                            name={fullName}
                                        />
                                        <div className="right-2 top-1/2 -translate-y-1/2 absolute">
                                            <Button onClick={() => dispatch(addFriend(id))} className="px-4 !h-10">
                                                Kết bạn
                                            </Button>
                                        </div>
                                    </div> 
                                }
                                return null
                            } 
                        }
                    />
                </div>
            </div>
        </div>
    </div>
}