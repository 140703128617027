import { FactoryRepository } from "../../../services";
import { call, put, select, takeLatest } from "redux-saga/effects";
import * as types from '../../types'
import { SagaIterator } from "redux-saga";
import { uploadFile } from "../file";
import { v4 as uuidv4 } from 'uuid';

const repository = FactoryRepository.get('authentication')

const handle = async (fullName: string, avatar: string) => {
    return await repository.update(fullName, avatar)
}

function* editProfile(action: any): SagaIterator {
    try {
        const { profile } = yield select(({auth}) => auth)
        const { fullName, avatar } = action.payload
        yield put({type: types.LOADING_EDIT_ON, payload: true})
        if(profile?.lastAvatar !== avatar) {
            const links = yield call(uploadFile, avatar, uuidv4())
            yield call(handle, fullName, links.url)
            yield put({type: types.EDIT_PROFILE_SUCCESS, payload: { fullName, avatar: links.url }})
        }
        if(profile?.lastAvatar === avatar) {
            yield call(handle, fullName, avatar)
            yield put({type: types.EDIT_PROFILE_SUCCESS, payload: { fullName, avatar }})
        }
        
        yield put({type: types.SET_FORM_SETTING_TYPE, payload: null})
        yield put({type: types.LOADING_EDIT_OFF, payload: false})
    } catch (error) {
        yield put({type: types.EDIT_PROFILE_FAILED, payload: null})
        yield put({type: types.LOADING_EDIT_OFF, payload: false})
    }
}

export function* watchEditProfile() {
    yield takeLatest(types.EDIT_PROFILE_REQUEST, editProfile)
}