import { useDispatch } from "react-redux"
import { logout, setFormConversationType } from "../../../redux/actions"
import { MenuMolecules } from "./menu"
import { useState } from "react"
import classNames from "classnames"
import { ButtonMenu } from "../../atoms"
import { Icon } from "../../../icon"

export const MenuLeft = () => {
    const dispatch = useDispatch()
    const [openMenu, setOpenMenu] = useState(false)
    const listMenu = [
        {
            text: 'Danh bạ',
            icon: 'user',          
            action: () => dispatch(setFormConversationType('contact'))
        },
        {
            text: 'Lời mời kết bạn',
            icon: 'mail',
            action: () => dispatch(setFormConversationType('friendRequest'))         
        },
        {
            text: 'Cuộc họp (đang phát triển)',
            icon: 'meet',
            action: () => {}
        },
        {
            text: 'Danh sách công việc (đang phát triển)',
            icon: 'task',
            action: () => {}
        },
        {
            text: 'Giao diện tối (đang phát triển)',
            icon: 'moon',
            action: () => {}
        },
        {
            text: 'Cài đặt',
            icon: 'setting',          
            action: () => dispatch(setFormConversationType("setting"))
        },
        {
            text: 'Đăng xuất',
            icon: 'logout',          
            action: () => dispatch(logout())
        },
    ]

    const classes = classNames(
        'h-10 w-10 flex justify-center items-center rounded-full',
        {
          'bg-light-secondary-text-color' : openMenu  
        }
    )

    return <MenuMolecules
        onOutsideClick={() => setOpenMenu(false)}
        open={openMenu} 
        ButtonComponent={<button onClick={() => setOpenMenu(!openMenu)} className={classes}>
            <Icon type="menu" />
        </button>}
        list={listMenu.map(({text, action, icon}: {text: string, action: () => void, icon: any}) => (
            <ButtonMenu key={text} onClick={action}>
                <Icon type={icon} />
                {text}
            </ButtonMenu>
        ))}
    /> 
}