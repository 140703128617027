import { NavigateFunction } from "react-router-dom";
import { LeftSideBarType } from "../../services/interfaces";
import { IQueryApi } from "../../services/interfaces/query";
import * as types from '../types'
import { SettingType } from "../../services/interfaces/setting";

export const getConversation = (query: IQueryApi) => ({
    type: types.GET_CONVERSATION_REQUEST,
    payload: query
})

export const getConversationById = (id: number) => ({
    type: types.GET_CONVERSATION_ID_REQUEST,
    payload: id
})

export const createConversationFriend = (friendId: number, navigate: NavigateFunction) => ({
    type: types.CREATE_CONVERSATION_REQUEST_FRIEND,
    payload: { friendId, navigate }
})

export const createConversationGroup = (data: any) => ({
    type: types.CREATE_CONVERSATION_REQUEST_GROUP,
    payload: data
})

export const setFormConversationType = (type: LeftSideBarType) => ({
    type: types.SET_FORM_CONVERSATION_TYPE,
    payload: type
})

export const setFormSettingType = (type: SettingType) => ({
    type: types.SET_FORM_SETTING_TYPE,
    payload: type
})