'use client'

import { Transition, Dialog } from "@headlessui/react"
import { Fragment, ReactNode } from "react"

export const DialogMolecules = ({open, closeModal, children}: {open: boolean, closeModal: () => void, children: ReactNode}) => {
    return <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-[#0000004d]" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >   
                        <Dialog.Panel className="w-full max-w-[420px] bg-white rounded-lg" style={{ boxShadow: '0 2px 2px #00000024, 0 3px 1px -2px #0000001f, 0 1px 5px #0003' }}>
                            {children}
                        </Dialog.Panel>
                    </Transition.Child>
                </div>    
            </div>
        </Dialog>
    </Transition>
}