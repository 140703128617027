import { useEffect, useRef, useState } from "react"
import { Icon } from "../../../icon"
import { DialogMolecules } from "../dialog"
import { EditAvatar } from "../EditAvatar"
import { BackIconBTN, Input, LottieFile } from "../../atoms"
import { useDispatch, useSelector } from "react-redux"
import { EditProfileRedux, setFormSettingType } from "../../../redux/actions"

export const EditProfile = () => {
    const inputRef = useRef<HTMLInputElement>(null)
    const { loadingButton, profile } = useSelector(({auth}) => auth)
    const [image, setImage] = useState<any>(null)
    const [thumbnail, setThumbnail] = useState<any>(profile?.lastAvatar ? `${process.env.REACT_APP_IMAGE}${profile?.lastAvatar}` : null)
    const [fullName, setFullName] = useState(profile?.fullName ?? '')
    const dispatch = useDispatch()
    useEffect(() => {
        if(profile?.fullName) {
            setFullName(profile?.fullName)
        }
    }, [profile?.fullName])

    const editProfile = async () => {
        if(thumbnail !== `${process.env.REACT_APP_IMAGE}${profile?.lastAvatar}`) {
            const response = await fetch(thumbnail);
            const blob = await response.blob();
            dispatch(EditProfileRedux(fullName, blob))
        }
        if(thumbnail === `${process.env.REACT_APP_IMAGE}${profile?.lastAvatar}`) {

        }
    }

    return <div className="flex justify-center px-4">
        <input ref={inputRef} className="hidden" type="file" accept="image/*" onChange={(e) => {
            const file = e.target.files
            if(file && file[0].type.startsWith('image/')) {
                setImage(file[0])
            }
            e.target.value = '';
        }} />
        <DialogMolecules open={image !== null} closeModal={() => setImage(null)}>
            <div className="flex flex-col gap-2 items-center py-2">
                <div className="flex gap-4 pl-4 pt-4 items-center w-full">
                    <button onClick={() => setImage(null)} className="transition-all p-2 hover:bg-light-secondary-text-color rounded-full">
                        <Icon type="Xmark" />
                    </button>
                    <span className="font-medium">Chỉnh sửa ảnh đại diện</span>
                </div>
                <EditAvatar image={image} result={e => {
                    setImage(null)
                    setThumbnail(e)
                }} />
            </div>
        </DialogMolecules>
        <div className="top-0 left-0 w-full h-full z-[1] flex flex-col gap-4 bg-white absolute">
            <div className="flex items-center gap-2 py-2 px-4">
                <div className="h-10 w-10">
                    <BackIconBTN onClick={() => dispatch(setFormSettingType(null))} />
                </div>
                <span className="font-medium text-lg">Chỉnh sửa thông tin</span>
            </div>
            <div className="flex justify-center px-4">
                <div onClick={() => inputRef?.current?.click()} style={{ background: thumbnail ? '' : 'linear-gradient(#7cb7f2, #3390ec)' }} className="h-[120px] w-[120px] rounded-full cursor-pointer group relative">
                    {
                        thumbnail && <img src={thumbnail} width={120} height={120} alt="preview" className="rounded-full" />
                    }
                    <div className="top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute">
                        <Icon type='camera' className="!w-[50px] !h-[50px] group-hover:scale-125 transition-all duration-300" />
                    </div>
                </div>
            </div>
            <div className="w-full px-4">
                <Input value={fullName} onChange={(e) => setFullName(e.target.value)} label="Họ và tên" />
            </div>
            <div className="h-3 bg-[#f4f4f5] px-4" />
            {
                fullName !== '' && <button onClick={editProfile} className="h-[54px] w-[54px] flex items-center justify-center rounded-full bg-[#3390ec] hover:bg-dark-primary-color z-20 right-5 bottom-5 absolute text-white">
                    {
                        loadingButton
                        ? <LottieFile src='/lottie_file/loading.json' width={50} height={50} />
                        : <Icon type="arrowRight" />
                    }
                </button>
            }
        </div>
    </div>
}