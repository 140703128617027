import * as types from '../types'

const initialState = {
    startCall: null,
    receiveCall: null,
}

export const CallReducer = (state = initialState, action:any) => {
    const { type, payload } = action
    switch(type){
        case types.START_CALL:
            return {
                ...state,
                startCall: payload
            }
        case types.RECEIVE_CALL:
            return {
                ...state,
                receiveCall: payload,
            }
        case types.END_CALL:
            return initialState
        default: 
            return state
    }
}