import repository from "../repository"

export class AuthRepository {
    resource = 'authentication'

    async login(email: string, password: string) {
        return await (await repository.post(`${this.resource}/login`, {email, password})).data
    }
    async register(email: string, password: string, fullName: string, phone: string) {
        return await (await repository.post(`${this.resource}/register`, { email, password, fullName, phone })).data
    }
    async profile() {
        return await (await repository.get(`${this.resource}/profile`)).data
    }
    async update(fullName: string, avatar: string) {
        return await (await repository.put(`${this.resource}/profile`, { fullName, avatar })).data
    }
    async editProfile(fullName: string, avatar?: string) {
        return await (await repository.put(`${this.resource}/profile`, { fullName, avatar })).data
    }
    async changePassword(oldPassword: string, newPassword: string, confirmPassword: string) {
        return await repository.put(`${this.resource}/change-password`, { oldPassword, newPassword, confirmPassword })
    }
    async logout() {
        return await repository.post(`${this.resource}/logout`)
    }
}
