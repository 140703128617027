export const GET_CONVERSATION_REQUEST = 'GET_CONVERSATION_REQUEST'
export const GET_CONVERSATION_SUCCESS = 'GET_CONVERSATION_SUCCESS'
export const GET_CONVERSATION_FAILED = 'GET_CONVERSATION_FAILED'

export const CREATE_CONVERSATION_REQUEST_FRIEND = 'CREATE_CONVERSATION_REQUEST_FRIEND'
export const CREATE_CONVERSATION_SUCCESS_FRIEND = 'CREATE_CONVERSATION_SUCCESS_FRIEND'
export const CREATE_CONVERSATION_FAILED_FRIEND = 'CREATE_CONVERSATION_FAILED_FRIEND'

export const CREATE_CONVERSATION_REQUEST_GROUP = 'CREATE_CONVERSATION_REQUEST_GROUP'
export const CREATE_CONVERSATION_SUCCESS_GROUP = 'CREATE_CONVERSATION_SUCCESS_GROUP'
export const CREATE_CONVERSATION_FAILED_GROUP = 'CREATE_CONVERSATION_FAILED_GROUP'

export const LOADING_CONVERSATION_ON_GROUP = 'LOADING_CONVERSATION_ON_GROUP'
export const LOADING_CONVERSATION_OFF_GROUP = 'LOADING_CONVERSATION_OFF_GROUP'

export const GET_CONVERSATION_ID_REQUEST = 'GET_CONVERSATION_ID_REQUEST'
export const GET_CONVERSATION_ID_SUCCESS = 'GET_CONVERSATION_ID_SUCCESS'
export const GET_CONVERSATION_ID_FAILED = 'GET_CONVERSATION_ID_FAILED'

export const GET_CONVERSATION_ID_LOADING_ON = 'GET_CONVERSATION_ID_LOADING_ON'
export const GET_CONVERSATION_ID_LOADING_OFF = 'GET_CONVERSATION_ID_LOADING_OFF'

export const GET_CONVERSATION_LOADING_ON = 'GET_CONVERSATION_LOADING_ON'
export const GET_CONVERSATION_LOADING_OFF = 'GET_CONVERSATION_LOADING_OFF'

export const GET_CONVERSATION_LOADING_FIRST = 'GET_CONVERSATION_LOADING_FIRST'

export const SET_FORM_CONVERSATION_TYPE = 'SET_FORM_CONVERSATION_TYPE'

export const SET_FORM_SETTING_TYPE = 'SET_FORM_SETTING_TYPE'