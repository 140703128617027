//People
export const Emoji = new Map([
    ["😀", "1f600"],
    ["😃", "1f603"],
    ["😄", "1f604"],
    ["😁", "1f601"],
    ["😆", "1f606"],
    ["😅", "1f605"],
    ["🤣", "1f923"],
    ["😂", "1f602"],
    ["🙂", "1f642"],
    ["🙃", "1f643"],
    ["🫠", "1fae0"],
    ["😉", "1f609"],
    ["😊", "1f60a"],
    ["😇", "1f607"],
    ["🥰", "1f970"],
    ["😍", "1f60d"],
    ["🤩", "1f929"],
    ["😘", "1f618"],
    ["👋", "1f44b"]
])