import { SagaIterator } from "redux-saga";
import { FactoryRepository } from "../../../services";
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from '../../types'

const repository = FactoryRepository.get('conversation')

const handle = async (friendId: number) => {
    return await (await repository.fromFriend(friendId)).data
}

function* createConversationFriend(action: any): SagaIterator {
    try {
        const result = yield call(handle, action.payload.friendId)
        yield put({type: types.CREATE_CONVERSATION_SUCCESS_FRIEND, payload: result})
        yield call(action.payload.navigate, `/messages/${result.id}`)
    } catch (error) {
        yield put({type: types.CREATE_CONVERSATION_FAILED_FRIEND})
    }  
}

export function* watchCreateConversationFriend() {
    yield takeLatest(types.CREATE_CONVERSATION_REQUEST_FRIEND, createConversationFriend)
}

