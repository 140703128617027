import { MutableRefObject } from "react"
import { Icon } from "../../../icon";

export const CallMolecules = ({
    endCall,
    userVideo,
    partnerVideo
}: {
    endCall: () => void,
    userVideo: MutableRefObject<HTMLVideoElement | null>;
    partnerVideo: MutableRefObject<HTMLVideoElement | null>;
}) => {
    return <div className="relative bg-[#3390ec] min-w-[460px] min-h-[600px] rounded-lg">
        <video playsInline ref={userVideo} autoPlay className="h-full rounded-lg" />
        <div className="bottom-4 right-4 absolute w-[150px] h-[225px] bg-black rounded-lg">
            <video playsInline ref={partnerVideo} autoPlay className="h-full rounded-lg" />
        </div>
        <div className="bottom-0 left-0 w-full absolute flex items-center justify-between p-4">
            {/* loa ngoài */}
            {/* video */}
            {/* tắt tiếng */}
            {/* kết thúc cuộc gọi */}
            <button onClick={endCall} className="text-white h-12 w-12 bg-[#e53935] rounded-full flex items-center justify-center">
                <Icon type="callX" />
            </button>
        </div>
    </div>
}