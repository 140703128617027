import repository from "../repository"

export class MediaRepository {
    resource = 'medias'
    
    async chunk(data: any) {
        return await repository.post(`${this.resource}/chunk`, data)
    }
    async merge(fileUuid: string, filename: string, conversationId: any) {
        return await (await repository.post(`${this.resource}/merge`, { fileUuid, filename, conversationId: conversationId ?? 0 })).data
    }
}
