import { ChangeEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getFriend } from "../redux/actions"

export const ContactHook = () => {
    const dispatch = useDispatch()
    const { nextPage, data, loading } = useSelector(({friend}) => friend)
    const [search, setSearch] = useState('')
    useEffect(() => {
        if(Object.entries(data).length === 0 && loading) {
            dispatch(getFriend({ page: 1 }))
        }
    }, [dispatch, data, loading])

    const HandleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
        dispatch(getFriend({page: 1, keyword: value, }))
    }

    const loadingInfinity = () => {
        dispatch(getFriend({ page: nextPage, keyword: search }))
    }
    return {
        search,
        HandleSearch,
        loadingInfinity
    }
}