import { ChangeEvent, FormEvent, useState } from "react"
import { useDispatch } from "react-redux"
import { RegExp } from "../services/regExp"
import { register, setFormType } from "../redux/actions"

const test = RegExp.getInstance()

export const RegisterHook = () => {
    const dispatch = useDispatch()

    const [data, setData] = useState({
        fullName: '',
        phone: '',
        email: '',
        password: ''
    })

    const [error, setError] = useState({
        phone: '',
        email: '',
        password: ''
    })

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target
        setData({...data, [name]: value})
        let err = {
            phone: '',
            email: '',
            password: ''
        }
        if(name === 'phone' && value !== '') {
            err.phone = ''
        }
        if(name === 'email' && value !== '') {
            err.email = ''
        }
        if(name === 'password' && value !== '') {
            err.password = ''
        }
        setError(err)
    }

    const { email, password, fullName, phone } = data

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        let err = {
            phone: '',
            email: '',
            password: ''
        }
        if(!test.isValidVietnamesePhoneNumber(phone)) {
            err.phone = 'Số điện thoại không đúng'
        }
        if(!test.validateEmail(email)) {
            err.email = 'Email không đúng'
        }
        if(!test.validatePasswordLength(password)) {
            err.password = 'Ít nhất 8 ký tự có chứa ít nhất một chữ cái viết thường, một chữ cái viết hoa, một chữ số và một ký tự đặc biệt'
        }
        setError(err)
        if(
            test.isValidVietnamesePhoneNumber(phone) &&
            test.validateEmail(email) && 
            test.validatePasswordLength(password)
        ) {
            dispatch(register(fullName, phone.replace("0", "+84"), email, password))
        }
    }

    const switchLogin = () => dispatch(setFormType('login'))

    return {
        onSubmit,
        onChange,
        error,
        fullName,
        email,
        password, 
        phone,
        switchLogin
    }
}