import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { getAccessToken } from "../../getToken";
import { RefreshToken } from "../refreshToken";
import { toast } from "react-toastify";

export function configRequest(config: InternalAxiosRequestConfig) {
    const accessToken = getAccessToken();
    if(accessToken) {
        config.headers.Authorization =  `Bearer ${accessToken}`
    }
    return config
}

export function handleResponse(response: AxiosResponse) {
    return response
}

export async function ErrorResponse(error: AxiosError): Promise<AxiosResponse> {
    if (!error.response) {
        // Xử lý lỗi mạng
        console.log('Network Error');
        toast.error('Mất kết nối mạng')
    }
    else {
        switch (error.response.status) {
            case 401:
                // Xử lý lỗi 401
                console.log('Error 401: Unauthorized');
                if (error.config && error.config.url) {
                    const errorURL = error.config.url;
                    if (![
                        '/login', 
                        '/register', 
                        '/refresh-token'
                    ].some(path => errorURL.includes(path))) {
                        try {
                            const accessToken = await RefreshToken()
                            error.config.headers.Authorization = `Bearer ${accessToken}`
                            return axios.request(error.config)
                        } catch (err) {
                            return Promise.reject(err);
                        }
                    }
                }
                break;
            case 403:
                // Xử lý lỗi 403
                console.log('Error 403: Forbidden');
                break;
            case 429:
                // Xử lý lỗi 429
                console.log('Error 429: Too Many Requests');
                break;
            case 500:
                // Xử lý lỗi 500
                console.log('Error 500: Internal Server Error');
                break;
            default:
                // Xử lý các trường hợp khác
                console.log(`Error ${error.response.status}`);
        }
    }
    return Promise.reject(error);
}
