import { SagaIterator } from "redux-saga";
import { IQueryApi } from "../../../services/interfaces/query";
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from '../../types'
import { FactoryRepository } from "../../../services";

const repository = FactoryRepository.get('friendRequest')

const handle = async (query: IQueryApi) => {
    return await repository.allFriend(query)
}

function* getFriend(action: any): SagaIterator {
    const { page } = action.payload
    try {
        yield put({type: types.LOADING_FRIEND_ON, payload: { check: true, page }})
        const result = yield call(handle, action.payload)
        yield put({type: types.GET_FRIEND_SUCCESS, payload: result})
        yield put({type: types.LOADING_FRIEND_OFF, payload: { check: false, page }})
    } catch (error) {
        yield put({type: types.GET_FRIEND_FAILED, payload: null})
        yield put({type: types.LOADING_FRIEND_OFF, payload: { check: false, page }})
    } 
}

export function* watchGetFriend() {
    yield takeLatest(types.GET_FRIEND_REQUEST, getFriend)
}
